import SubmitButton from 'components/Button/SubmitButton'
import RadioButton from 'components/Form/RadioButton'
import PageHedding from 'components/PageHedding/PageHedding'
import { FC, useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { functions } from 'fb/index'
import styles from 'styles/components/template.module.scss'
import { setIsLoading } from 'reducks/loading/slice'
import { getShopSubscriptionById } from 'reducks/shopSubscriptions/slice'
import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { RootState } from 'reducks/reducers'
import checkIcon from 'images/check-icon.png'

const EditPlanTemplate: FC = () => {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const location = useLocation()
  const path = location.pathname
  const [plan, setPlan] = useState<
    'liteMonth' | 'liteYear' | 'standardMonth' | 'standardYear'
  >('standardYear')
  const [currentPlan, setCurrentPlan] = useState<
    'liteMonth' | 'liteYear' | 'standardMonth'
  >('standardMonth')
  const [isDone, setIsDone] = useState(false)
  const [failedMsg, setFailedMsg] = useState('')
  const { staff } = useSelector((state: RootState) => state.staffSlice)
  const handleSubmit = async () => {
    if (staff?.storeId) {
      await apiUpdateStripeSubscriptionDaseruno(staff.storeId, plan)
    } else {
      setFailedMsg('アカウント情報の取得に失敗しました')
    }
  }
  const initData = useCallback(
    async (storeId: string) => {
      await dispatch(setIsLoading({ isLoading: true }))
      const shopSubscriptionPayload = await dispatch(
        getShopSubscriptionById({ storeId: storeId }),
      )
      const shopSubscription = shopSubscriptionPayload.payload
      await apiGetStripeSubscriptionDaseruno(
        shopSubscription.stripe_daseruno_subscription_id,
      )
    },
    [dispatch],
  )
  const apiGetStripeSubscriptionDaseruno = useCallback(
    async (subscriptionId: string) => {
      await dispatch(setIsLoading({ isLoading: true }))
      const apiGetStripeSubscriptionDaseruno = httpsCallable(
        functions,
        'apiGetStripeSubscriptionDaseruno',
      )
      apiGetStripeSubscriptionDaseruno({
        subscriptionId,
      })
        .then(async (result: HttpsCallableResult<any>) => {
          console.log(result.data)
          if (
            result.data.items &&
            result.data.items.data &&
            result.data.items.data.length > 0 &&
            result.data.items.data[0].price &&
            result.data.items.data[0].price.id
          ) {
            const plan =
              result.data.items.data[0].price.id ===
              process.env.REACT_APP_STRIPE_PRICE_LITE_MONTH_ID
                ? 'liteMonth'
                : result.data.items.data[0].price.id ===
                  process.env.REACT_APP_STRIPE_PRICE_LITE_YEAR_ID
                ? 'liteYear'
                : [
                    process.env.REACT_APP_STRIPE_PRICE_STANDARD_MONTH_ID,
                    process.env.REACT_APP_STRIPE_PRICE_STANDARD_MONTH_V2_ID,
                  ].includes(result.data.items.data[0].price.id)
                ? 'standardMonth'
                : null
            if (plan) {
              setCurrentPlan(plan)
            }
            if (plan === null || plan === 'standardMonth') {
              // スタンダードプランからプラン変更はできないためホームに飛ばす
              navigate('/initload')
              window.scrollTo(0, 0)
            }
          }
        })
        .catch((error) => {
          console.log(error.message)
        })
        .finally(() => dispatch(setIsLoading({ isLoading: false })))
    },
    [dispatch],
  )
  const apiUpdateStripeSubscriptionDaseruno = useCallback(
    async (storeId: string, plan: string) => {
      await dispatch(setIsLoading({ isLoading: true }))
      const apiUpdateStripeSubscriptionDaseruno = httpsCallable(
        functions,
        'apiUpdateStripeSubscriptionDaseruno',
      )
      apiUpdateStripeSubscriptionDaseruno({
        storeId,
        plan,
      })
        .then(async (result: HttpsCallableResult<any>) => {
          console.log(result.data)
          setIsDone(true)
        })
        .catch((error) => {
          dispatch(setIsLoading({ isLoading: false }))
          setFailedMsg(error.message)
          console.log(error.message)
        })
        .finally(() => dispatch(setIsLoading({ isLoading: false })))
    },
    [dispatch],
  )
  useEffect(() => {
    if (!staff) {
      navigate('/initload?path=' + path)
      window.scrollTo(0, 0)
      return
    }
    if (staff?.storeId) {
      initData(staff.storeId)
    }
  }, [staff])
  return (
    <div className={styles.container} style={{ paddingBottom: 80 }}>
      <PageHedding title="契約情報" to="/plan_confirm" />
      <div className={styles.flexColumnCenter}>
        <p className={styles.registerplan_section_title}>ご利用プラン変更</p>
        <p className={styles.registerplan_section_subtitle}>
          機能一覧と価格をご確認の上、変更したいプランをお選びください。
        </p>
        <div className={styles.registerplan_plan_name_container}>
          <p className={styles.registerplan_plan_name_container_text}>
            お客様が契約しているプランは
          </p>
          <p className={styles.registerplan_plan_name_container_text_red}>
            {currentPlan === 'liteMonth'
              ? 'ライトプラン(月々払い)'
              : currentPlan === 'liteYear'
              ? 'ライトプラン(年払い)'
              : currentPlan === 'standardMonth'
              ? 'スタンダードプラン(月々払い)'
              : ''}
            です
          </p>
        </div>
        <div className={styles.registerplan_table}>
          <div className={styles.registerplan_table_flex}>
            <div className={styles.registerplan_table_side}></div>
            <div className={styles.registerplan_table_main}>
              <div className={styles.registerplan_label}>NEW</div>
            </div>
            <div className={styles.registerplan_table_main}></div>
          </div>

          <div className={styles.registerplan_table_flex}>
            <div
              className={`${styles.registerplan_table_side} ${styles.registerplan_table_body} ${styles.registerplan_table_border_right}`}
            ></div>
            <div
              className={`${styles.registerplan_table_main} ${styles.registerplan_table_body} ${styles.registerplan_table_border_left} ${styles.registerplan_table_border_right}`}
            >
              <div className={styles.registerplan_table_main_text}>
                スタンダードプラン
              </div>
            </div>
            <div
              className={`${styles.registerplan_table_main} ${styles.registerplan_table_body} ${styles.registerplan_table_border_left}`}
            >
              <div className={styles.registerplan_table_main_text}>
                ライトプラン
              </div>
            </div>
          </div>

          <div className={styles.registerplan_table_flex}>
            <div
              className={`${styles.registerplan_table_side} ${styles.registerplan_table_head} ${styles.registerplan_table_left}`}
            >
              <div className={styles.registerplan_table_text}>
                原価&売価管理
              </div>
            </div>
            <div
              className={`${styles.registerplan_table_main} ${styles.registerplan_table_head}`}
            ></div>
            <div
              className={`${styles.registerplan_table_main} ${styles.registerplan_table_head} ${styles.registerplan_table_right}`}
            ></div>
          </div>

          <div className={styles.registerplan_table_flex}>
            <div
              className={`${styles.registerplan_table_side} ${styles.registerplan_table_body} ${styles.registerplan_table_border_bottom} ${styles.registerplan_table_border_right}`}
            >
              <div className={styles.registerplan_table_text}>
                商品別原価管理機能
              </div>
            </div>
            <div
              className={`${styles.registerplan_table_main} ${styles.registerplan_table_body} ${styles.registerplan_table_border_left} ${styles.registerplan_table_border_bottom} ${styles.registerplan_table_border_right}`}
            >
              <img
                className={styles.registerplan_table_check_image}
                src={checkIcon}
                alt="check"
              ></img>
            </div>
            <div
              className={`${styles.registerplan_table_main} ${styles.registerplan_table_body} ${styles.registerplan_table_border_left} ${styles.registerplan_table_border_bottom}`}
            ></div>
          </div>

          <div className={styles.registerplan_table_flex}>
            <div
              className={`${styles.registerplan_table_side} ${styles.registerplan_table_body} ${styles.registerplan_table_border_top} ${styles.registerplan_table_border_bottom} ${styles.registerplan_table_border_right}`}
            >
              <div className={styles.registerplan_table_text}>
                売価サジェスト機能
              </div>
            </div>
            <div
              className={`${styles.registerplan_table_main} ${styles.registerplan_table_body} ${styles.registerplan_table_border_left} ${styles.registerplan_table_border_top} ${styles.registerplan_table_border_bottom} ${styles.registerplan_table_border_right}`}
            >
              <img
                className={styles.registerplan_table_check_image}
                src={checkIcon}
                alt="check"
              ></img>
            </div>
            <div
              className={`${styles.registerplan_table_main} ${styles.registerplan_table_body} ${styles.registerplan_table_border_left} ${styles.registerplan_table_border_top} ${styles.registerplan_table_border_bottom}`}
            ></div>
          </div>

          <div className={styles.registerplan_table_flex}>
            <div
              className={`${styles.registerplan_table_side} ${styles.registerplan_table_body} ${styles.registerplan_table_border_top} ${styles.registerplan_table_border_right}`}
            >
              <div className={styles.registerplan_table_center}>
                <div className={styles.registerplan_table_text_red}>
                  請求書取り込み代行
                </div>
                <div className={styles.registerplan_table_sub_text_red}>
                  (無料キャンペーン中)
                </div>
              </div>
            </div>
            <div
              className={`${styles.registerplan_table_main} ${styles.registerplan_table_body} ${styles.registerplan_table_border_left} ${styles.registerplan_table_border_top} ${styles.registerplan_table_border_right}`}
            >
              <img
                className={styles.registerplan_table_check_image}
                src={checkIcon}
                alt="check"
              ></img>
            </div>
            <div
              className={`${styles.registerplan_table_main} ${styles.registerplan_table_body} ${styles.registerplan_table_border_left} ${styles.registerplan_table_border_top}`}
            ></div>
          </div>

          <div className={styles.registerplan_table_flex}>
            <div
              className={`${styles.registerplan_table_side} ${styles.registerplan_table_head} ${styles.registerplan_table_left}`}
            >
              <div className={styles.registerplan_table_text}>
                栄養成分表示算出
              </div>
            </div>
            <div
              className={`${styles.registerplan_table_main} ${styles.registerplan_table_head}`}
            ></div>
            <div
              className={`${styles.registerplan_table_main} ${styles.registerplan_table_head} ${styles.registerplan_table_right}`}
            ></div>
          </div>

          <div className={styles.registerplan_table_flex}>
            <div
              className={`${styles.registerplan_table_side} ${styles.registerplan_table_body} ${styles.registerplan_table_border_bottom} ${styles.registerplan_table_border_right}`}
            >
              <div className={styles.registerplan_table_text}>
                レシピ管理機能
              </div>
            </div>
            <div
              className={`${styles.registerplan_table_main} ${styles.registerplan_table_body} ${styles.registerplan_table_border_left} ${styles.registerplan_table_border_bottom} ${styles.registerplan_table_border_right}`}
            >
              <img
                className={styles.registerplan_table_check_image}
                src={checkIcon}
                alt="check"
              ></img>
            </div>
            <div
              className={`${styles.registerplan_table_main} ${styles.registerplan_table_body} ${styles.registerplan_table_border_left} ${styles.registerplan_table_border_bottom}`}
            >
              <img
                className={styles.registerplan_table_check_image}
                src={checkIcon}
                alt="check"
              ></img>
            </div>
          </div>

          <div className={styles.registerplan_table_flex}>
            <div
              className={`${styles.registerplan_table_side} ${styles.registerplan_table_body} ${styles.registerplan_table_border_top} ${styles.registerplan_table_border_bottom} ${styles.registerplan_table_border_right}`}
            >
              <div className={styles.registerplan_table_center}>
                <div className={styles.registerplan_table_text_red}>
                  レシピ登録代行
                </div>
                <div className={styles.registerplan_table_sub_text_red}>
                  (無料キャンペーン中)
                </div>
              </div>
            </div>
            <div
              className={`${styles.registerplan_table_main} ${styles.registerplan_table_body} ${styles.registerplan_table_border_left} ${styles.registerplan_table_border_top} ${styles.registerplan_table_border_bottom} ${styles.registerplan_table_border_right}`}
            >
              <img
                className={styles.registerplan_table_check_image}
                src={checkIcon}
                alt="check"
              ></img>
            </div>
            <div
              className={`${styles.registerplan_table_main} ${styles.registerplan_table_body} ${styles.registerplan_table_border_left} ${styles.registerplan_table_border_top} ${styles.registerplan_table_border_bottom}`}
            >
              <img
                className={styles.registerplan_table_check_image}
                src={checkIcon}
                alt="check"
              ></img>
            </div>
          </div>

          <div className={styles.registerplan_table_flex}>
            <div
              className={`${styles.registerplan_table_side} ${styles.registerplan_table_body} ${styles.registerplan_table_border_top} ${styles.registerplan_table_border_bottom} ${styles.registerplan_table_border_right}`}
            >
              <div className={styles.registerplan_table_text}>
                栄養成分値算出機能
              </div>
            </div>
            <div
              className={`${styles.registerplan_table_main} ${styles.registerplan_table_body} ${styles.registerplan_table_border_left} ${styles.registerplan_table_border_top} ${styles.registerplan_table_border_bottom} ${styles.registerplan_table_border_right}`}
            >
              <img
                className={styles.registerplan_table_check_image}
                src={checkIcon}
                alt="check"
              ></img>
            </div>
            <div
              className={`${styles.registerplan_table_main} ${styles.registerplan_table_body} ${styles.registerplan_table_border_left} ${styles.registerplan_table_border_top} ${styles.registerplan_table_border_bottom}`}
            >
              <img
                className={styles.registerplan_table_check_image}
                src={checkIcon}
                alt="check"
              ></img>
            </div>
          </div>

          <div className={styles.registerplan_table_flex}>
            <div
              className={`${styles.registerplan_table_side} ${styles.registerplan_table_body} ${styles.registerplan_table_border_top} ${styles.registerplan_table_border_bottom} ${styles.registerplan_table_border_right}`}
            >
              <div className={styles.registerplan_table_text}>
                裏貼シール出力機能
              </div>
            </div>
            <div
              className={`${styles.registerplan_table_main} ${styles.registerplan_table_body} ${styles.registerplan_table_border_left} ${styles.registerplan_table_border_top} ${styles.registerplan_table_border_bottom} ${styles.registerplan_table_border_right}`}
            >
              <img
                className={styles.registerplan_table_check_image}
                src={checkIcon}
                alt="check"
              ></img>
            </div>
            <div
              className={`${styles.registerplan_table_main} ${styles.registerplan_table_body} ${styles.registerplan_table_border_left} ${styles.registerplan_table_border_top} ${styles.registerplan_table_border_bottom}`}
            >
              <img
                className={styles.registerplan_table_check_image}
                src={checkIcon}
                alt="check"
              ></img>
            </div>
          </div>

          <div className={styles.registerplan_table_flex}>
            <div
              className={`${styles.registerplan_table_side} ${styles.registerplan_table_price} ${styles.registerplan_table_border_top} ${styles.registerplan_table_border_right}`}
            >
              <div className={styles.registerplan_table_text}>価格</div>
            </div>
            <div
              className={`${styles.registerplan_table_main} ${styles.registerplan_table_price} ${styles.registerplan_table_border_left} ${styles.registerplan_table_border_top} ${styles.registerplan_table_border_right}`}
            >
              <div>
                <p className={styles.registerplan_table_plan_box_head}>
                  月々払い
                </p>
                <p
                  className={styles.registerplan_table_plan_box_text_red}
                  style={{ marginTop: 8, fontSize: 12 }}
                >
                  全ての機能を使い、管理をラクにしたい方にオススメ！
                </p>
                <p
                  className={styles.registerplan_plan_box_text}
                  style={{
                    fontSize: 48,
                    fontWeight: 'bold',
                    marginTop: 8,
                    marginBottom: 8,
                  }}
                >
                  <span style={{ fontSize: 18, marginRight: 8 }}>月額</span>
                  9,800
                  <span style={{ fontSize: 18, marginLeft: 8 }}>
                    円（税抜）
                  </span>
                </p>
                <p
                  className={styles.registerplan_table_plan_box_text}
                  style={{ marginTop: 8 }}
                >
                  （税込価格　10,780円）
                </p>
                <p
                  className={styles.registerplan_table_plan_box_text}
                  style={{ marginTop: 8 }}
                >
                  ※契約期間は最低1年間となります。
                </p>
                <div className={styles.registerplan_table_plan_box_radio}>
                  <RadioButton
                    style={{ marginTop: 24 }}
                    isChecked={plan === 'standardYear'}
                    label=""
                    onChange={() => {
                      setPlan('standardYear')
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className={`${styles.registerplan_table_main} ${styles.registerplan_table_price} ${styles.registerplan_table_border_left} ${styles.registerplan_table_border_top}`}
            >
              <div>
                <p className={styles.registerplan_table_plan_box_head}>
                  {currentPlan === 'liteMonth' ? '年払い' : '月々払い'}
                </p>
                <p
                  className={styles.registerplan_table_plan_box_text_red}
                  style={{ marginTop: 8, fontSize: 12 }}
                >
                  {currentPlan === 'liteMonth'
                    ? 'お得に使いたい方にオススメ！'
                    : 'お支払いを分割したい方にオススメ！'}
                </p>
                <p
                  className={styles.registerplan_plan_box_text}
                  style={{
                    fontSize: 48,
                    fontWeight: 'bold',
                    marginTop: 8,
                    marginBottom: 8,
                  }}
                >
                  <span style={{ fontSize: 18, marginRight: 8 }}>月額</span>
                  {currentPlan === 'liteMonth' ? '8,164' : '8,910'}
                  <span style={{ fontSize: 18, marginLeft: 8 }}>
                    円/月（税抜）
                  </span>
                </p>
                <p
                  className={styles.registerplan_table_plan_box_text}
                  style={{ marginTop: 8 }}
                >
                  {currentPlan === 'liteMonth'
                    ? '一括：97,964円（税抜）'
                    : '9,800円（税込）'}
                </p>
                <p
                  className={styles.registerplan_table_plan_box_text}
                  style={{ marginTop: 8 }}
                >
                  ※契約期間は最低1年間となります。
                </p>
                <div className={styles.registerplan_table_plan_box_radio}>
                  {currentPlan === 'liteMonth' ? (
                    <RadioButton
                      style={{ marginTop: 24 }}
                      isChecked={plan === 'liteYear'}
                      label=""
                      onChange={() => {
                        setPlan('liteYear')
                      }}
                    />
                  ) : (
                    <RadioButton
                      style={{ marginTop: 24 }}
                      isChecked={plan === 'liteMonth'}
                      label=""
                      onChange={() => {
                        setPlan('liteMonth')
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={styles.login_button_container}
          style={{ marginTop: 64 }}
        >
          <SubmitButton
            label="変更する"
            color="orange"
            size="semilarge"
            icon="none"
            onClick={() => handleSubmit()}
          />
        </div>
        <p
          className={styles.registerplan_notice_text}
          style={{ marginTop: 42 }}
        >
          ※
          次回更新日の1日前までに変更しない場合、自動的に現プランが継続されます。
        </p>
        <p
          className={styles.registerplan_notice_text}
          style={{ marginTop: -12 }}
        >
          ※
          ご解約をご希望される場合は、ご解約の前月の末日までにご担当のダセルーノスタッフまでご連絡ください。
        </p>
      </div>
      {isDone && (
        <div className={styles.modal_account}>
          <div className={styles.modal_account_text}>
            プランの変更が完了しました
          </div>
          <div className={styles.modalButtonContainer}>
            <SubmitButton
              label="トップへ進む"
              color="orange"
              size="xmiddle"
              icon="none"
              onClick={() => {
                navigate('/initload')
                window.scrollTo(0, 0)
              }}
            />
          </div>
        </div>
      )}
      {!!failedMsg && (
        <div className={styles.modal_account}>
          <div
            className={styles.modal_account_text}
            style={{ color: '#ED7C7C' }}
          >
            プランの変更に失敗しました
          </div>
          <div
            className={styles.modal_account_sub_text}
            style={{ color: '#ED7C7C' }}
          >
            {failedMsg}
          </div>
          <div className={styles.modalButtonContainer}>
            <SubmitButton
              label="戻る"
              color="white"
              size="xmiddle"
              icon="none"
              onClick={() => {
                setFailedMsg('')
                window.scrollTo(0, 0)
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default EditPlanTemplate
