import { FC, useState, useCallback, useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import styles from 'styles/components/template.module.scss'
import DataLoad from 'components/Init/DataLoad'
import PageHedding from 'components/PageHedding/PageHedding'
import InputTextWithLabel from 'components/Form/WithLabel/InputText'
import InputTextAndUnitWithLabel from 'components/Form/WithLabel/InputTextAndUnit'
import InputTextSuggestWithLabel, {
  suggestInfo,
} from 'components/Form/WithLabel/InputTextSuggest'
import TextAreaWithLabel from 'components/Form/WithLabel/TextArea'
import SelectBoxWithLabel from 'components/Form/WithLabel/SelectBox'
import CheckBoxWithLabel from 'components/Form/WithLabel/CheckBox'
import RadioButtonWithLabel from 'components/Form/WithLabel/RadioButton'
import RadioButtonAndInputTextWithLabel from 'components/Form/WithLabel/RadioButtonAndInputText'
import SubmitButton from 'components/Button/SubmitButton'
import { Allergens } from 'reducks/bread/slice'
import AddFormConstituteMaterialNameInformation, {
  ConstituteMaterialNameInfo,
  isGeneticallyInfo,
} from 'components/Form/AddForm/ConstituteMaterialNameInformation'
import AddFormAdditiveUseInformation, {
  AdditiveUseInfo,
} from 'components/Form/AddForm/AdditiveUseInformation'
import { InternalCapacityInfo } from 'components/Form/AddForm/InternalCapacityInformation'
import {
  createMaterial,
  ConstituteMaterialType,
  AdditiveInputType,
  getMaterialById,
  clearMaterial,
  updateMaterial,
  getMaterials,
  InternalCapacityType,
} from 'reducks/material/slice'
import {
  getTopBreadIdsRecursivelyById,
  DataType,
  setBreadBackSealStatusByBreadId,
} from 'reducks/business/slice'
import { RootState } from 'reducks/reducers'
import { SelectOption } from 'components/Form/SelectBox'
import { getMaterialCategories } from 'reducks/materialCategory/slice'
import { getMaterialSubCategories } from 'reducks/materialSubCategory/slice'
import { setIsLoading } from 'reducks/loading/slice'

const MaterialOperationTemplate: FC = () => {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const location = useLocation()
  dayjs.locale('ja')
  // 更新の場合か確認
  const path = location.pathname
  const materialId = path.split('/material/edit/')[1]
    ? path.split('/material/edit/')[1]
    : null
  // 基本情報
  const [categoryList, setCategoryList] = useState<SelectOption[]>([])
  const [subCategoryList, setSubCategoryList] = useState<SelectOption[]>([])
  const [subCategoryFilterList, setSubCategoryFilterList] = useState<
    SelectOption[]
  >([])
  const [category, setCategory] = useState<string>('')
  const [subCategory, setSubCategory] = useState<string>('')
  const [manufacturer, setManufacturer] = useState<string>('')
  const [productName, setProductName] = useState<string>('')
  const [productNameKana, setProductNameKana] = useState<string>('')
  const [notationFluctuation, setNotationFluctuation] = useState<string>('')
  const [originName, setOriginName] = useState<string>('')
  const [singleMaterial, setSingleMaterial] = useState<boolean>(true)
  const [compositeMaterial, setCompositeMaterial] = useState<boolean>(false)
  const [noMaterial, setNoMaterial] = useState<boolean>(false)
  const [materialName, setMaterialName] = useState<string>('')
  const [rawMaterialName, setRawMaterialName] = useState<string>('')
  const [compositeMaterialName, setCompositeMaterialName] = useState<string>('')
  const [geneticallyNoSelection, setGeneticallyNoSelection] =
    useState<boolean>(true)
  const [geneticallyModified, setGeneticallyModified] = useState<boolean>(false)
  const [geneticallyNoDifference, setGeneticallyNoDifference] =
    useState<boolean>(false)
  const [constituteMaterialNameInfos, setConstituteMaterialNameInfos] =
    useState<ConstituteMaterialNameInfo[]>([])
  const [baseAllergenEgg, setBaseAllergenEgg] = useState<boolean>(false)
  const [baseAllergenMilk, setBaseAllergenMilk] = useState<boolean>(false)
  const [baseAllergenWheat, setBaseAllergenWheat] = useState<boolean>(false)
  const [baseAllergenShrimp, setBaseAllergenShrimp] = useState<boolean>(false)
  const [baseAllergenCrub, setBaseAllergenCrub] = useState<boolean>(false)
  const [baseAllergenPeanuts, setBaseAllergenPeanuts] = useState<boolean>(false)
  const [baseAllergenSoba, setBaseAllergenSoba] = useState<boolean>(false)
  const [baseAllergenAlmond, setBaseAllergenAlmond] = useState<boolean>(false)
  const [baseAllergenAbalone, setBaseAllergenAbalone] = useState<boolean>(false)
  const [baseAllergenSquid, setBaseAllergenSquid] = useState<boolean>(false)
  const [baseAllergenIkura, setBaseAllergenIkura] = useState<boolean>(false)
  const [baseAllergenOrange, setBaseAllergenOrange] = useState<boolean>(false)
  const [baseAllergenCashewnuts, setBaseAllergenCashewnuts] =
    useState<boolean>(false)
  const [baseAllergenKiwifruit, setBaseAllergenKiwifruit] =
    useState<boolean>(false)
  const [baseAllergenBeef, setBaseAllergenBeef] = useState<boolean>(false)
  const [baseAllergenWalnut, setBaseAllergenWalnut] = useState<boolean>(false)
  const [baseAllergenSesame, setBaseAllergenSesame] = useState<boolean>(false)
  const [baseAllergenSalmon, setBaseAllergenSalmon] = useState<boolean>(false)
  const [baseAllergenMackerel, setBaseAllergenMackerel] =
    useState<boolean>(false)
  const [baseAllergenSoy, setBaseAllergenSoy] = useState<boolean>(false)
  const [baseAllergenChicken, setBaseAllergenChicken] = useState<boolean>(false)
  const [baseAllergenBanana, setBaseAllergenBanana] = useState<boolean>(false)
  const [baseAllergenPork, setBaseAllergenPork] = useState<boolean>(false)
  const [baseAllergenMacadamianuts, setBaseAllergenMacadamianuts] =
    useState<boolean>(false)
  const [baseAllergenPeach, setBaseAllergenPeach] = useState<boolean>(false)
  const [baseAllergenYam, setBaseAllergenYam] = useState<boolean>(false)
  const [baseAllergenApple, setBaseAllergenApple] = useState<boolean>(false)
  const [baseAllergenGelatine, setBaseAllergenGelatine] =
    useState<boolean>(false)
  const [baseHaveAllergen, setBaseHaveAllergen] = useState<boolean>(true)

  // 内容量
  const [internalCapacityInfos, setInternalCapacityInfos] = useState<
    InternalCapacityInfo[]
  >([])
  // 添加物
  const [additiveUseInfos, setAdditiveUseInfos] = useState<AdditiveUseInfo[]>(
    [],
  )
  const [additiveAllergenEgg, setAdditiveAllergenEgg] = useState<boolean>(false)
  const [additiveAllergenMilk, setAdditiveAllergenMilk] =
    useState<boolean>(false)
  const [additiveAllergenWheat, setAdditiveAllergenWheat] =
    useState<boolean>(false)
  const [additiveAllergenShrimp, setAdditiveAllergenShrimp] =
    useState<boolean>(false)
  const [additiveAllergenCrub, setAdditiveAllergenCrub] =
    useState<boolean>(false)
  const [additiveAllergenPeanuts, setAdditiveAllergenPeanuts] =
    useState<boolean>(false)
  const [additiveAllergenSoba, setAdditiveAllergenSoba] =
    useState<boolean>(false)
  const [additiveAllergenAlmond, setAdditiveAllergenAlmond] =
    useState<boolean>(false)
  const [additiveAllergenAbalone, setAdditiveAllergenAbalone] =
    useState<boolean>(false)
  const [additiveAllergenSquid, setAdditiveAllergenSquid] =
    useState<boolean>(false)
  const [additiveAllergenIkura, setAdditiveAllergenIkura] =
    useState<boolean>(false)
  const [additiveAllergenOrange, setAdditiveAllergenOrange] =
    useState<boolean>(false)
  const [additiveAllergenCashewnuts, setAdditiveAllergenCashewnuts] =
    useState<boolean>(false)
  const [additiveAllergenKiwifruit, setAdditiveAllergenKiwifruit] =
    useState<boolean>(false)
  const [additiveAllergenBeef, setAdditiveAllergenBeef] =
    useState<boolean>(false)
  const [additiveAllergenWalnut, setAdditiveAllergenWalnut] =
    useState<boolean>(false)
  const [additiveAllergenSesame, setAdditiveAllergenSesame] =
    useState<boolean>(false)
  const [additiveAllergenSalmon, setAdditiveAllergenSalmon] =
    useState<boolean>(false)
  const [additiveAllergenMackerel, setAdditiveAllergenMackerel] =
    useState<boolean>(false)
  const [additiveAllergenSoy, setAdditiveAllergenSoy] = useState<boolean>(false)
  const [additiveAllergenChicken, setAdditiveAllergenChicken] =
    useState<boolean>(false)
  const [additiveAllergenBanana, setAdditiveAllergenBanana] =
    useState<boolean>(false)
  const [additiveAllergenPork, setAdditiveAllergenPork] =
    useState<boolean>(false)
  const [additiveAllergenMacadamianuts, setAdditiveAllergenMacadamianuts] =
    useState<boolean>(false)
  const [additiveAllergenPeach, setAdditiveAllergenPeach] =
    useState<boolean>(false)
  const [additiveAllergenYam, setAdditiveAllergenYam] = useState<boolean>(false)
  const [additiveAllergenApple, setAdditiveAllergenApple] =
    useState<boolean>(false)
  const [additiveAllergenGelatine, setAdditiveAllergenGelatine] =
    useState<boolean>(false)
  const [additiveHaveAllergen, setAdditiveHaveAllergen] =
    useState<boolean>(true)

  // 栄養成分表示
  const [energy, setEnergy] = useState<string>('')
  const [protein, setProtein] = useState<string>('')
  const [lipid, setLipid] = useState<string>('')
  const [carbohydrate, setCarbohydrate] = useState<string>('')
  const [saltEquivalent, setSaltEquivalent] = useState<string>('')
  const [sodiumCalculation, setSodiumCalculation] = useState<string>('')

  // その他
  const [sourceInformation, setSourceInformation] = useState<string>('')
  const [url1, setUrl1] = useState<string>('')
  const [url2, setUrl2] = useState<string>('')
  const [url3, setUrl3] = useState<string>('')
  const [classifiedName, setClassifiedName] = useState<string>('')
  const [author, setAuthor] = useState<string>('')
  const [memo, setMemo] = useState<string>('')
  const [createdAt, setCreatedAt] = useState<string>('')
  const [updatedAt, setUpdatedAt] = useState<string>('')

  // 基本情報
  const handleCategory = (category: string) => {
    setCategory(category)
    setCategoryErrorText(null)
  }
  const handleSubCategory = (subCategory: string) => {
    setSubCategory(subCategory)
    setSubCategoryErrorText(null)
  }
  const handleManufacturer = (manufacturer: string) => {
    setManufacturer(manufacturer)
    setManufacturerErrorText(null)
  }
  const handleManufacturerSuggest = (info: suggestInfo) => {
    setManufacturer(info.name)
    setManufacturerErrorText(null)
  }
  const handleProductName = (productName: string) => {
    setProductName(productName)
    setProductNameErrorText(null)
  }
  const handleProductNameKana = (productNameKana: string) => {
    setProductNameKana(productNameKana)
    setProductNameKanaErrorText(null)
  }
  const handleNotationFluctuation = (notationFluctuation: string) => {
    setNotationFluctuation(notationFluctuation)
  }
  const handleOriginName = (originName: string) => {
    setOriginName(originName)
  }
  const handleSingleOrCompositeMaterial = (value: string) => {
    setSingleMaterial(value === 'single')
    setCompositeMaterial(value === 'composite')
    setNoMaterial(value === 'no')
  }
  const handleMaterialName = (materialName: string) => {
    setMaterialName(materialName)
    setMaterialNameErrorText(null)
  }
  const handleRawMaterialName = (rawMaterialName: string) => {
    setRawMaterialName(rawMaterialName)
    setRawMaterialNameErrorText(null)
  }
  const handleCompositeMaterialName = (compositeMaterialName: string) => {
    setCompositeMaterialName(compositeMaterialName)
  }
  const handleGenetically = (isGenetically: isGeneticallyInfo) => {
    setGeneticallyNoSelection(isGenetically.noSelection)
    setGeneticallyModified(isGenetically.modified)
    setGeneticallyNoDifference(isGenetically.noDifference)
  }
  const handleConstituteMaterialNameInfos = (
    constituteMaterialNameInfos: ConstituteMaterialNameInfo[],
  ) => {
    setConstituteMaterialNameInfos(constituteMaterialNameInfos)
  }
  const handleBaseAllelgen = (setAllelgen: string) => {
    if (setAllelgen === Allergens.EGG) {
      setBaseAllergenEgg(!baseAllergenEgg ? true : false)
    }
    if (setAllelgen === Allergens.MILK) {
      setBaseAllergenMilk(!baseAllergenMilk ? true : false)
    }
    if (setAllelgen === Allergens.WHEAT) {
      setBaseAllergenWheat(!baseAllergenWheat ? true : false)
    }
    if (setAllelgen === Allergens.SHRIMP) {
      setBaseAllergenShrimp(!baseAllergenShrimp ? true : false)
    }
    if (setAllelgen === Allergens.CRUB) {
      setBaseAllergenCrub(!baseAllergenCrub ? true : false)
    }
    if (setAllelgen === Allergens.PEANUTS) {
      setBaseAllergenPeanuts(!baseAllergenPeanuts ? true : false)
    }
    if (setAllelgen === Allergens.SOBA) {
      setBaseAllergenSoba(!baseAllergenSoba ? true : false)
    }
    if (setAllelgen === Allergens.ALMOND) {
      setBaseAllergenAlmond(!baseAllergenAlmond ? true : false)
    }
    if (setAllelgen === Allergens.ABALONE) {
      setBaseAllergenAbalone(!baseAllergenAbalone ? true : false)
    }
    if (setAllelgen === Allergens.SQUID) {
      setBaseAllergenSquid(!baseAllergenSquid ? true : false)
    }
    if (setAllelgen === Allergens.IKURA) {
      setBaseAllergenIkura(!baseAllergenIkura ? true : false)
    }
    if (setAllelgen === Allergens.ORANGE) {
      setBaseAllergenOrange(!baseAllergenOrange ? true : false)
    }
    if (setAllelgen === Allergens.CASHEWNUTS) {
      setBaseAllergenCashewnuts(!baseAllergenCashewnuts ? true : false)
    }
    if (setAllelgen === Allergens.KIWIFRUIT) {
      setBaseAllergenKiwifruit(!baseAllergenKiwifruit ? true : false)
    }
    if (setAllelgen === Allergens.BEEF) {
      setBaseAllergenBeef(!baseAllergenBeef ? true : false)
    }
    if (setAllelgen === Allergens.WALNUT) {
      setBaseAllergenWalnut(!baseAllergenWalnut ? true : false)
    }
    if (setAllelgen === Allergens.SESAME) {
      setBaseAllergenSesame(!baseAllergenSesame ? true : false)
    }
    if (setAllelgen === Allergens.SALMON) {
      setBaseAllergenSalmon(!baseAllergenSalmon ? true : false)
    }
    if (setAllelgen === Allergens.MACKEREL) {
      setBaseAllergenMackerel(!baseAllergenMackerel ? true : false)
    }
    if (setAllelgen === Allergens.SOY) {
      setBaseAllergenSoy(!baseAllergenSoy ? true : false)
    }
    if (setAllelgen === Allergens.CHICKEN) {
      setBaseAllergenChicken(!baseAllergenChicken ? true : false)
    }
    if (setAllelgen === Allergens.BANANA) {
      setBaseAllergenBanana(!baseAllergenBanana ? true : false)
    }
    if (setAllelgen === Allergens.PORK) {
      setBaseAllergenPork(!baseAllergenPork ? true : false)
    }
    if (setAllelgen === Allergens.MACADAMIANUTS) {
      setBaseAllergenMacadamianuts(!baseAllergenMacadamianuts ? true : false)
    }
    if (setAllelgen === Allergens.PEACH) {
      setBaseAllergenPeach(!baseAllergenPeach ? true : false)
    }
    if (setAllelgen === Allergens.YAM) {
      setBaseAllergenYam(!baseAllergenYam ? true : false)
    }
    if (setAllelgen === Allergens.APPLE) {
      setBaseAllergenApple(!baseAllergenApple ? true : false)
    }
    if (setAllelgen === Allergens.GELATINE) {
      setBaseAllergenGelatine(!baseAllergenGelatine ? true : false)
    }
  }
  const handleBaseHaveAllergen = () => {
    setBaseHaveAllergen(!baseHaveAllergen ? true : false)
    setBaseAllergenEgg(false)
    setBaseAllergenMilk(false)
    setBaseAllergenWheat(false)
    setBaseAllergenShrimp(false)
    setBaseAllergenCrub(false)
    setBaseAllergenPeanuts(false)
    setBaseAllergenSoba(false)
    setBaseAllergenAlmond(false)
    setBaseAllergenAbalone(false)
    setBaseAllergenSquid(false)
    setBaseAllergenIkura(false)
    setBaseAllergenOrange(false)
    setBaseAllergenCashewnuts(false)
    setBaseAllergenKiwifruit(false)
    setBaseAllergenBeef(false)
    setBaseAllergenWalnut(false)
    setBaseAllergenSesame(false)
    setBaseAllergenSalmon(false)
    setBaseAllergenMackerel(false)
    setBaseAllergenSoy(false)
    setBaseAllergenChicken(false)
    setBaseAllergenBanana(false)
    setBaseAllergenPork(false)
    setBaseAllergenMacadamianuts(false)
    setBaseAllergenPeach(false)
    setBaseAllergenYam(false)
    setBaseAllergenApple(false)
    setBaseAllergenGelatine(false)
  }

  // 添加物
  const handleAdditiveUseInfos = (additiveUseInfos: AdditiveUseInfo[]) => {
    setAdditiveUseInfos(additiveUseInfos)
  }
  const handleAdditiveAllelgen = (setAllelgen: string) => {
    if (setAllelgen === Allergens.EGG) {
      setAdditiveAllergenEgg(!additiveAllergenEgg ? true : false)
    }
    if (setAllelgen === Allergens.MILK) {
      setAdditiveAllergenMilk(!additiveAllergenMilk ? true : false)
    }
    if (setAllelgen === Allergens.WHEAT) {
      setAdditiveAllergenWheat(!additiveAllergenWheat ? true : false)
    }
    if (setAllelgen === Allergens.SHRIMP) {
      setAdditiveAllergenShrimp(!additiveAllergenShrimp ? true : false)
    }
    if (setAllelgen === Allergens.CRUB) {
      setAdditiveAllergenCrub(!additiveAllergenCrub ? true : false)
    }
    if (setAllelgen === Allergens.PEANUTS) {
      setAdditiveAllergenPeanuts(!additiveAllergenPeanuts ? true : false)
    }
    if (setAllelgen === Allergens.SOBA) {
      setAdditiveAllergenSoba(!additiveAllergenSoba ? true : false)
    }
    if (setAllelgen === Allergens.ALMOND) {
      setAdditiveAllergenAlmond(!additiveAllergenAlmond ? true : false)
    }
    if (setAllelgen === Allergens.ABALONE) {
      setAdditiveAllergenAbalone(!additiveAllergenAbalone ? true : false)
    }
    if (setAllelgen === Allergens.SQUID) {
      setAdditiveAllergenSquid(!additiveAllergenSquid ? true : false)
    }
    if (setAllelgen === Allergens.IKURA) {
      setAdditiveAllergenIkura(!additiveAllergenIkura ? true : false)
    }
    if (setAllelgen === Allergens.ORANGE) {
      setAdditiveAllergenOrange(!additiveAllergenOrange ? true : false)
    }
    if (setAllelgen === Allergens.CASHEWNUTS) {
      setAdditiveAllergenCashewnuts(!additiveAllergenCashewnuts ? true : false)
    }
    if (setAllelgen === Allergens.KIWIFRUIT) {
      setAdditiveAllergenKiwifruit(!additiveAllergenKiwifruit ? true : false)
    }
    if (setAllelgen === Allergens.BEEF) {
      setAdditiveAllergenBeef(!additiveAllergenBeef ? true : false)
    }
    if (setAllelgen === Allergens.WALNUT) {
      setAdditiveAllergenWalnut(!additiveAllergenWalnut ? true : false)
    }
    if (setAllelgen === Allergens.SESAME) {
      setAdditiveAllergenSesame(!additiveAllergenSesame ? true : false)
    }
    if (setAllelgen === Allergens.SALMON) {
      setAdditiveAllergenSalmon(!additiveAllergenSalmon ? true : false)
    }
    if (setAllelgen === Allergens.MACKEREL) {
      setAdditiveAllergenMackerel(!additiveAllergenMackerel ? true : false)
    }
    if (setAllelgen === Allergens.SOY) {
      setAdditiveAllergenSoy(!additiveAllergenSoy ? true : false)
    }
    if (setAllelgen === Allergens.CHICKEN) {
      setAdditiveAllergenChicken(!additiveAllergenChicken ? true : false)
    }
    if (setAllelgen === Allergens.BANANA) {
      setAdditiveAllergenBanana(!additiveAllergenBanana ? true : false)
    }
    if (setAllelgen === Allergens.PORK) {
      setAdditiveAllergenPork(!additiveAllergenPork ? true : false)
    }
    if (setAllelgen === Allergens.MACADAMIANUTS) {
      setAdditiveAllergenMacadamianuts(
        !additiveAllergenMacadamianuts ? true : false,
      )
    }
    if (setAllelgen === Allergens.PEACH) {
      setAdditiveAllergenPeach(!additiveAllergenPeach ? true : false)
    }
    if (setAllelgen === Allergens.YAM) {
      setAdditiveAllergenYam(!additiveAllergenYam ? true : false)
    }
    if (setAllelgen === Allergens.APPLE) {
      setAdditiveAllergenApple(!additiveAllergenApple ? true : false)
    }
    if (setAllelgen === Allergens.GELATINE) {
      setAdditiveAllergenGelatine(!additiveAllergenGelatine ? true : false)
    }
  }
  const handleAdditiveHaveAllergen = () => {
    setAdditiveHaveAllergen(!additiveHaveAllergen ? true : false)
    setAdditiveAllergenEgg(false)
    setAdditiveAllergenMilk(false)
    setAdditiveAllergenWheat(false)
    setAdditiveAllergenShrimp(false)
    setAdditiveAllergenCrub(false)
    setAdditiveAllergenPeanuts(false)
    setAdditiveAllergenSoba(false)
    setAdditiveAllergenAlmond(false)
    setAdditiveAllergenAbalone(false)
    setAdditiveAllergenSquid(false)
    setAdditiveAllergenIkura(false)
    setAdditiveAllergenOrange(false)
    setAdditiveAllergenCashewnuts(false)
    setAdditiveAllergenKiwifruit(false)
    setAdditiveAllergenBeef(false)
    setAdditiveAllergenWalnut(false)
    setAdditiveAllergenSesame(false)
    setAdditiveAllergenSalmon(false)
    setAdditiveAllergenMackerel(false)
    setAdditiveAllergenSoy(false)
    setAdditiveAllergenChicken(false)
    setAdditiveAllergenBanana(false)
    setAdditiveAllergenPork(false)
    setAdditiveAllergenMacadamianuts(false)
    setAdditiveAllergenPeach(false)
    setAdditiveAllergenYam(false)
    setAdditiveAllergenApple(false)
    setAdditiveAllergenGelatine(false)
  }

  // 栄養成分表示
  const handleEnergy = (energy: string) => {
    // 半角数字に変換
    energy = energy.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(energy) && Number(energy) !== 0) {
      return
    }
    setEnergy(energy)
    setEnergyErrorText(null)
  }
  const handleProtein = (protein: string) => {
    // 半角数字に変換
    protein = protein.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(protein) && Number(protein) !== 0) {
      return
    }
    setProtein(protein)
    setProteinErrorText(null)
  }
  const handleLipid = (lipid: string) => {
    // 半角数字に変換
    lipid = lipid.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(lipid) && Number(lipid) !== 0) {
      return
    }
    setLipid(lipid)
    setLipidErrorText(null)
  }
  const handleCarbohydrate = (carbohydrate: string) => {
    // 半角数字に変換
    carbohydrate = carbohydrate.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(carbohydrate) && Number(carbohydrate) !== 0) {
      return
    }
    setCarbohydrate(carbohydrate)
    setCarbohydrateErrorText(null)
  }
  const handleSaltEquivalent = (saltEquivalent: string) => {
    // 半角数字に変換
    saltEquivalent = saltEquivalent.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(saltEquivalent) && Number(saltEquivalent) !== 0) {
      return
    }
    setSaltEquivalent(saltEquivalent)
    setSaltEquivalentErrorText(null)
  }
  const handleSodiumCalculation = (sodiumCalculation: string) => {
    // 半角数字に変換
    sodiumCalculation = sodiumCalculation.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(sodiumCalculation) && Number(sodiumCalculation) !== 0) {
      return
    }
    setSodiumCalculation(sodiumCalculation)
  }

  // その他
  const handleSourceInformation = (sourceInformation: string) => {
    setSourceInformation(sourceInformation)
  }
  const handleUrl1 = (url1: string) => {
    setUrl1(url1)
  }
  const handleUrl2 = (url2: string) => {
    setUrl2(url2)
  }
  const handleUrl3 = (url3: string) => {
    setUrl3(url3)
  }
  const handleClassifiedName = (classifiedName: string) => {
    setClassifiedName(classifiedName)
  }
  const handleAuthor = (author: string) => {
    setAuthor(author)
  }
  const handleMemo = (memo: string) => {
    setMemo(memo)
  }

  // Validation
  const [categoryErrorText, setCategoryErrorText] = useState<string | null>(
    null,
  )
  const [subCategoryErrorText, setSubCategoryErrorText] = useState<
    string | null
  >(null)
  const [manufacturerErrorText, setManufacturerErrorText] = useState<
    string | null
  >(null)
  const [productNameErrorText, setProductNameErrorText] = useState<
    string | null
  >(null)
  const [productNameKanaErrorText, setProductNameKanaErrorText] = useState<
    string | null
  >(null)
  const [materialNameErrorText, setMaterialNameErrorText] = useState<
    string | null
  >(null)
  const [rawMaterialNameErrorText, setRawMaterialNameErrorText] = useState<
    string | null
  >(null)
  const [compositeMaterialNameErrorText, setCompositeMaterialNameErrorText] =
    useState<string | null>(null)
  const [constituteMaterialNameErrorText, setConstituteMaterialNameErrorText] =
    useState<string | null>(null)
  const [energyErrorText, setEnergyErrorText] = useState<string | null>(null)
  const [proteinErrorText, setProteinErrorText] = useState<string | null>(null)
  const [lipidErrorText, setLipidErrorText] = useState<string | null>(null)
  const [carbohydrateErrorText, setCarbohydrateErrorText] = useState<
    string | null
  >(null)
  const [saltEquivalentErrorText, setSaltEquivalentErrorText] = useState<
    string | null
  >(null)
  const [baseAllergenErrorText, setBaseAllergenErrorText] = useState<
    string | null
  >(null)
  const [additiveAllergenErrorText, setAdditiveAllergenErrorText] = useState<
    string | null
  >(null)

  const validation = () => {
    let isError = false
    if (!category) {
      isError = true
      setCategoryErrorText('入力されておりません')
    }
    if (!subCategory) {
      isError = true
      setSubCategoryErrorText('入力されておりません')
    }
    if (!manufacturer) {
      isError = true
      setManufacturerErrorText('入力されておりません')
    }
    if (!productName) {
      isError = true
      setProductNameErrorText('入力されておりません')
    }
    if (!productNameKana) {
      isError = true
      setProductNameKanaErrorText('入力されておりません')
    }
    if (singleMaterial && !materialName) {
      isError = true
      setMaterialNameErrorText('入力されておりません')
    }
    if (singleMaterial && !rawMaterialName) {
      isError = true
      setRawMaterialNameErrorText('入力されておりません')
    }
    if (compositeMaterial && !compositeMaterialName) {
      isError = true
      setCompositeMaterialNameErrorText('入力されておりません')
    }
    if (
      compositeMaterial &&
      !constituteMaterialNameInfos[0]['constituteMaterialName']
    ) {
      isError = true
      setConstituteMaterialNameErrorText('1つ以上情報を入力してください')
    }
    if (!energy) {
      isError = true
      setEnergyErrorText('入力されておりません')
    }
    if (!protein) {
      isError = true
      setProteinErrorText('入力されておりません')
    }
    if (!lipid) {
      isError = true
      setLipidErrorText('入力されておりません')
    }
    if (!carbohydrate) {
      isError = true
      setCarbohydrateErrorText('入力されておりません')
    }
    if (!saltEquivalent) {
      isError = true
      setSaltEquivalentErrorText('入力されておりません')
    }
    if (
      !noMaterial &&
      baseHaveAllergen &&
      !baseAllergenEgg &&
      !baseAllergenMilk &&
      !baseAllergenWheat &&
      !baseAllergenShrimp &&
      !baseAllergenCrub &&
      !baseAllergenPeanuts &&
      !baseAllergenSoba &&
      !baseAllergenAlmond &&
      !baseAllergenAbalone &&
      !baseAllergenSquid &&
      !baseAllergenIkura &&
      !baseAllergenOrange &&
      !baseAllergenCashewnuts &&
      !baseAllergenKiwifruit &&
      !baseAllergenBeef &&
      !baseAllergenWalnut &&
      !baseAllergenSesame &&
      !baseAllergenSalmon &&
      !baseAllergenMackerel &&
      !baseAllergenSoy &&
      !baseAllergenChicken &&
      !baseAllergenBanana &&
      !baseAllergenPork &&
      !baseAllergenMacadamianuts &&
      !baseAllergenPeach &&
      !baseAllergenYam &&
      !baseAllergenApple &&
      !baseAllergenGelatine
    ) {
      isError = true
      setBaseAllergenErrorText('入力されておりません')
    }
    if (
      additiveHaveAllergen &&
      !additiveAllergenEgg &&
      !additiveAllergenMilk &&
      !additiveAllergenWheat &&
      !additiveAllergenShrimp &&
      !additiveAllergenCrub &&
      !additiveAllergenPeanuts &&
      !additiveAllergenSoba &&
      !additiveAllergenAlmond &&
      !additiveAllergenAbalone &&
      !additiveAllergenSquid &&
      !additiveAllergenIkura &&
      !additiveAllergenOrange &&
      !additiveAllergenCashewnuts &&
      !additiveAllergenKiwifruit &&
      !additiveAllergenBeef &&
      !additiveAllergenWalnut &&
      !additiveAllergenSesame &&
      !additiveAllergenSalmon &&
      !additiveAllergenMackerel &&
      !additiveAllergenSoy &&
      !additiveAllergenChicken &&
      !additiveAllergenBanana &&
      !additiveAllergenPork &&
      !additiveAllergenMacadamianuts &&
      !additiveAllergenPeach &&
      !additiveAllergenYam &&
      !additiveAllergenApple &&
      !additiveAllergenGelatine
    ) {
      isError = true
      setAdditiveAllergenErrorText('入力されておりません')
    }
    const internalCapacityCheckList: string[] = []
    internalCapacityInfos.forEach((internalCapacityInfo) => {
      const checkValue =
        internalCapacityInfo.internalCapacityCount +
        '*' +
        internalCapacityInfo.internalCapacityUnit +
        '*' +
        internalCapacityInfo.numberItem +
        '*' +
        internalCapacityInfo.numberItemUnit
      internalCapacityCheckList.push(checkValue)
    })
    if (isError) {
      window.scrollTo(0, 0)
      return false
    }
    return true
  }

  const handleSubmit = async (to: string) => {
    const is_valid = validation()
    if (!is_valid) {
      return
    }
    dispatch(setIsLoading({ isLoading: true }))

    // base allergens
    const baseAllergens: string[] = []
    if (baseAllergenEgg) {
      baseAllergens.push(Allergens.EGG)
    }
    if (baseAllergenMilk) {
      baseAllergens.push(Allergens.MILK)
    }
    if (baseAllergenWheat) {
      baseAllergens.push(Allergens.WHEAT)
    }
    if (baseAllergenShrimp) {
      baseAllergens.push(Allergens.SHRIMP)
    }
    if (baseAllergenCrub) {
      baseAllergens.push(Allergens.CRUB)
    }
    if (baseAllergenPeanuts) {
      baseAllergens.push(Allergens.PEANUTS)
    }
    if (baseAllergenSoba) {
      baseAllergens.push(Allergens.SOBA)
    }
    if (baseAllergenAlmond) {
      baseAllergens.push(Allergens.ALMOND)
    }
    if (baseAllergenAbalone) {
      baseAllergens.push(Allergens.ABALONE)
    }
    if (baseAllergenSquid) {
      baseAllergens.push(Allergens.SQUID)
    }
    if (baseAllergenIkura) {
      baseAllergens.push(Allergens.IKURA)
    }
    if (baseAllergenOrange) {
      baseAllergens.push(Allergens.ORANGE)
    }
    if (baseAllergenCashewnuts) {
      baseAllergens.push(Allergens.CASHEWNUTS)
    }
    if (baseAllergenKiwifruit) {
      baseAllergens.push(Allergens.KIWIFRUIT)
    }
    if (baseAllergenBeef) {
      baseAllergens.push(Allergens.BEEF)
    }
    if (baseAllergenWalnut) {
      baseAllergens.push(Allergens.WALNUT)
    }
    if (baseAllergenSesame) {
      baseAllergens.push(Allergens.SESAME)
    }
    if (baseAllergenSalmon) {
      baseAllergens.push(Allergens.SALMON)
    }
    if (baseAllergenMackerel) {
      baseAllergens.push(Allergens.MACKEREL)
    }
    if (baseAllergenSoy) {
      baseAllergens.push(Allergens.SOY)
    }
    if (baseAllergenChicken) {
      baseAllergens.push(Allergens.CHICKEN)
    }
    if (baseAllergenBanana) {
      baseAllergens.push(Allergens.BANANA)
    }
    if (baseAllergenPork) {
      baseAllergens.push(Allergens.PORK)
    }
    if (baseAllergenMacadamianuts) {
      baseAllergens.push(Allergens.MACADAMIANUTS)
    }
    if (baseAllergenPeach) {
      baseAllergens.push(Allergens.PEACH)
    }
    if (baseAllergenYam) {
      baseAllergens.push(Allergens.YAM)
    }
    if (baseAllergenApple) {
      baseAllergens.push(Allergens.APPLE)
    }
    if (baseAllergenGelatine) {
      baseAllergens.push(Allergens.GELATINE)
    }

    // additive allergens
    const additiveAllergens: string[] = []
    if (additiveAllergenEgg) {
      additiveAllergens.push(Allergens.EGG)
    }
    if (additiveAllergenMilk) {
      additiveAllergens.push(Allergens.MILK)
    }
    if (additiveAllergenWheat) {
      additiveAllergens.push(Allergens.WHEAT)
    }
    if (additiveAllergenShrimp) {
      additiveAllergens.push(Allergens.SHRIMP)
    }
    if (additiveAllergenCrub) {
      additiveAllergens.push(Allergens.CRUB)
    }
    if (additiveAllergenPeanuts) {
      additiveAllergens.push(Allergens.PEANUTS)
    }
    if (additiveAllergenSoba) {
      additiveAllergens.push(Allergens.SOBA)
    }
    if (additiveAllergenAlmond) {
      additiveAllergens.push(Allergens.ALMOND)
    }
    if (additiveAllergenAbalone) {
      additiveAllergens.push(Allergens.ABALONE)
    }
    if (additiveAllergenSquid) {
      additiveAllergens.push(Allergens.SQUID)
    }
    if (additiveAllergenIkura) {
      additiveAllergens.push(Allergens.IKURA)
    }
    if (additiveAllergenOrange) {
      additiveAllergens.push(Allergens.ORANGE)
    }
    if (additiveAllergenCashewnuts) {
      additiveAllergens.push(Allergens.CASHEWNUTS)
    }
    if (additiveAllergenKiwifruit) {
      additiveAllergens.push(Allergens.KIWIFRUIT)
    }
    if (additiveAllergenBeef) {
      additiveAllergens.push(Allergens.BEEF)
    }
    if (additiveAllergenWalnut) {
      additiveAllergens.push(Allergens.WALNUT)
    }
    if (additiveAllergenSesame) {
      additiveAllergens.push(Allergens.SESAME)
    }
    if (additiveAllergenSalmon) {
      additiveAllergens.push(Allergens.SALMON)
    }
    if (additiveAllergenMackerel) {
      additiveAllergens.push(Allergens.MACKEREL)
    }
    if (additiveAllergenSoy) {
      additiveAllergens.push(Allergens.SOY)
    }
    if (additiveAllergenChicken) {
      additiveAllergens.push(Allergens.CHICKEN)
    }
    if (additiveAllergenBanana) {
      additiveAllergens.push(Allergens.BANANA)
    }
    if (additiveAllergenPork) {
      additiveAllergens.push(Allergens.PORK)
    }
    if (additiveAllergenMacadamianuts) {
      additiveAllergens.push(Allergens.MACADAMIANUTS)
    }
    if (additiveAllergenPeach) {
      additiveAllergens.push(Allergens.PEACH)
    }
    if (additiveAllergenYam) {
      additiveAllergens.push(Allergens.YAM)
    }
    if (additiveAllergenApple) {
      additiveAllergens.push(Allergens.APPLE)
    }
    if (additiveAllergenGelatine) {
      additiveAllergens.push(Allergens.GELATINE)
    }

    const notationFluctuationList: string[] = notationFluctuation.split(',')

    const constituteMaterial: ConstituteMaterialType[] = []
    await Promise.all(
      constituteMaterialNameInfos.map(async (constituteMaterialNameInfo) => {
        constituteMaterial.push({
          material_name: constituteMaterialNameInfo.constituteMaterialName,
          genetically_no_selection:
            constituteMaterialNameInfo.isGenetically.noSelection,
          genetically_modified:
            constituteMaterialNameInfo.isGenetically.modified,
          genetically_no_difference:
            constituteMaterialNameInfo.isGenetically.noDifference,
        })
      }),
    )

    const additiveInputs: AdditiveInputType[] = []
    await Promise.all(
      additiveUseInfos.map(async (additiveUseInfo) => {
        additiveInputs.push({
          additive_use_id: additiveUseInfo.use,
          additive_material: additiveUseInfo.substance,
          disabled_material_name: additiveUseInfo.disabled_material_name,
        })
      }),
    )

    const internalCapacity: InternalCapacityType[] = []
    await Promise.all(
      internalCapacityInfos.map(async (internalCapacityInfo) => {
        internalCapacity.push({
          internal_capacity_count: internalCapacityInfo.internalCapacityCount
            ? Number(internalCapacityInfo.internalCapacityCount)
            : null,
          internal_capacity_unit: internalCapacityInfo.internalCapacityUnit,
          number_item: internalCapacityInfo.numberItem
            ? Number(internalCapacityInfo.numberItem)
            : null,
          number_item_unit: internalCapacityInfo.numberItemUnit
            ? internalCapacityInfo.numberItemUnit
            : '個',
          jancode: internalCapacityInfo.jancode
            ? internalCapacityInfo.jancode
            : null,
          origin_name: internalCapacityInfo.originName
            ? internalCapacityInfo.originName
            : null,
        })
      }),
    )
    const internalCapacityInput: InternalCapacityType[] | null =
      internalCapacity.length === 1 &&
      internalCapacity[0].internal_capacity_count === null &&
      internalCapacity[0].number_item === null &&
      internalCapacity[0].jancode === null &&
      internalCapacity[0].origin_name === null
        ? null
        : internalCapacity

    if (materialId) {
      // Firestoreを更新
      await dispatch(
        updateMaterial({
          id: materialId,
          category: category,
          sub_category: subCategory,
          manufacturer: manufacturer,
          product_name: productName,
          product_name_kana: productNameKana,
          notation_fluctuation: notationFluctuationList,
          origin_name: originName,
          single_material: singleMaterial,
          composite_material: compositeMaterial,
          no_material: noMaterial,
          material_name: materialName,
          raw_material_name: rawMaterialName,
          composite_material_name: compositeMaterialName,
          genetically_no_selection: geneticallyNoSelection,
          genetically_modified: geneticallyModified,
          genetically_no_difference: geneticallyNoDifference,
          constitute_material: constituteMaterial,
          base_allergens: baseAllergens,
          internal_capacity: internalCapacityInput,
          additive_inputs: additiveInputs,
          additive_allergens: additiveAllergens,
          energy: Number(energy),
          protein: Number(protein),
          lipid: Number(lipid),
          carbohydrate: Number(carbohydrate),
          salt_equivalent: Number(saltEquivalent),
          source_information: sourceInformation,
          urls: [url1, url2, url3],
          classified_name: classifiedName,
          author: author,
          memo: memo,
        }),
      )
      // 関連するbread情報のステータスを更新する
      const updateStatusBreadIdListPayload = await dispatch(
        getTopBreadIdsRecursivelyById({
          id: materialId,
          dataType: DataType.MATERIAL,
        }),
      )
      const updateStatusBreadIdList: string[] =
        updateStatusBreadIdListPayload.payload
      await Promise.all(
        updateStatusBreadIdList.map(async (updateStatusBreadId) => {
          await dispatch(
            setBreadBackSealStatusByBreadId({ breadId: updateStatusBreadId }),
          )
        }),
      )
    } else {
      // Firestoreに登録
      await dispatch(
        createMaterial({
          category: category,
          sub_category: subCategory,
          manufacturer: manufacturer,
          product_name: productName,
          product_name_kana: productNameKana,
          notation_fluctuation: notationFluctuationList,
          origin_name: originName,
          single_material: singleMaterial,
          composite_material: compositeMaterial,
          no_material: noMaterial,
          material_name: materialName,
          raw_material_name: rawMaterialName,
          composite_material_name: compositeMaterialName,
          genetically_no_selection: geneticallyNoSelection,
          genetically_modified: geneticallyModified,
          genetically_no_difference: geneticallyNoDifference,
          constitute_material: constituteMaterial,
          base_allergens: baseAllergens,
          internal_capacity: internalCapacityInput,
          additive_inputs: additiveInputs,
          additive_allergens: additiveAllergens,
          energy: Number(energy),
          protein: Number(protein),
          lipid: Number(lipid),
          carbohydrate: Number(carbohydrate),
          salt_equivalent: Number(saltEquivalent),
          source_information: sourceInformation,
          urls: [url1, url2, url3],
          classified_name: classifiedName,
          author: author,
          memo: memo,
        }),
      )
    }
    await dispatch(clearMaterial())
    await dispatch(getMaterials())

    navigate(to)
    window.scrollTo(0, 0)
    dispatch(setIsLoading({ isLoading: false }))
  }

  const handleSodiumCalculationSubmit = () => {
    const saltEquivalent = (Number(sodiumCalculation) * 2.54) / 1000
    handleSaltEquivalent(String(saltEquivalent))
  }

  // const { materials } = useSelector((state: RootState) => state.materialSlice)
  // const createMaterialList = useCallback(async () => {
  //   await dispatch(getMaterials())

  //   // const categoriesPayload: CategoryType[] = categories.payload
  //   const materialManufacturerSuggestList: suggestInfo[] = []
  //   const productNameSuggestList: suggestInfo[] = []
  //   materials.forEach((material) => {
  //     materialManufacturerSuggestList.push({
  //       id: material.id,
  //       name: material.manufacturer,
  //     })
  //     productNameSuggestList.push({
  //       id: material.id,
  //       name: material.product_name,
  //     })
  //   })
  //   setMaterialManufacturerList(materialManufacturerSuggestList)
  //   setProductNameList(productNameSuggestList)
  // }, [dispatch, materials])

  // useLayoutEffect(() => {
  //   createMaterialList()
  // }, [])

  const { materialCategories } = useSelector(
    (state: RootState) => state.materialCategorySlice,
  )
  const createMaterialCategoryList = useCallback(async () => {
    await dispatch(getMaterialCategories())
    const categoryList: SelectOption[] = [
      {
        is_default: true,
        label: '----',
        value: '',
      },
    ]
    materialCategories.forEach((materialCategory) => {
      // TODO: リロード時(初回読み込み時)にFirestore参照型からidが取得できないための対応。根本原因を調査する。
      const id = materialCategory.material_category_ref.id
        ? materialCategory.material_category_ref.id
        : // @ts-ignore
          materialCategory.material_category_ref._key.path.segments[6]
      categoryList.push({
        is_default: false,
        label: materialCategory.name,
        value: id,
      })
    })
    setCategoryList(categoryList)
  }, [dispatch, materialCategories])
  const { materialSubCategories } = useSelector(
    (state: RootState) => state.materialSubCategorySlice,
  )

  const createMaterialSubCategoryList = useCallback(async () => {
    await dispatch(getMaterialSubCategories())
    const categoryList: SelectOption[] = [
      {
        is_default: true,
        label: '----',
        value: '',
      },
    ]
    materialSubCategories.forEach((materialSubCategory) => {
      // TODO: リロード時(初回読み込み時)にFirestore参照型からidが取得できないための対応。根本原因を調査する。
      const id = materialSubCategory.material_sub_category_ref.id
        ? materialSubCategory.material_sub_category_ref.id
        : // @ts-ignore
          materialSubCategory.material_sub_category_ref._key.path.segments[6]
      const filterId = materialSubCategory.material_category_ref.id
        ? materialSubCategory.material_category_ref.id
        : // @ts-ignore
          materialSubCategory.material_category_ref._key.path.segments[6]
      categoryList.push({
        is_default: false,
        label: materialSubCategory.name,
        filter: filterId,
        value: id,
      })
    })
    setSubCategoryList(categoryList)
    setSubCategoryFilterList(categoryList)
  }, [dispatch, materialSubCategories])

  useEffect(() => {
    createMaterialSubCategoryList()
    createMaterialCategoryList()
  }, [])

  const createMaterialSubCategoryFilterList = useCallback(async () => {
    const newSubCategoryList = subCategoryList.filter(
      (select) => select.filter === category || select.label === '----',
    )
    setSubCategoryFilterList(newSubCategoryList)
  }, [category])

  useLayoutEffect(() => {
    createMaterialSubCategoryFilterList()
  }, [category])

  const { material } = useSelector((state: RootState) => state.materialSlice)
  const { staff } = useSelector((state: RootState) => state.staffSlice)
  const setMaterialInfo = useCallback(
    async (materialId: string | null) => {
      dispatch(setIsLoading({ isLoading: true }))
      if (!staff) {
        navigate('/initload?path=' + path)
        window.scrollTo(0, 0)
        dispatch(setIsLoading({ isLoading: false }))
        return
      }
      await dispatch(clearMaterial())
      const constituteMaterialNameInfos: ConstituteMaterialNameInfo[] = []
      const additiveUseInfos: AdditiveUseInfo[] = []
      const internalCapacityInfos: InternalCapacityInfo[] = []
      if (materialId) {
        await dispatch(getMaterialById({ id: materialId }))
      }
      if (material) {
        const categoryId = material.material_category_ref.id
          ? material.material_category_ref.id
          : // @ts-ignore
            material.material_category_ref._key.path.segments[6]
        const subCategoryId = material.material_sub_category_ref.id
          ? material.material_sub_category_ref.id
          : // @ts-ignore
            material.material_sub_category_ref._key.path.segments[6]
        setCategory(categoryId)
        setSubCategory(subCategoryId)
        setManufacturer(material.manufacturer)
        setProductName(material.product_name)
        setProductNameKana(material.product_name_kana)
        setNotationFluctuation(material.notation_fluctuation.join(','))
        setOriginName(material.origin_name)
        setSingleMaterial(material.single_material)
        setCompositeMaterial(material.composite_material)
        setNoMaterial(material.no_material)
        setMaterialName(material.material_name)
        setRawMaterialName(material.raw_material_name)
        setCompositeMaterialName(material.composite_material_name)
        setGeneticallyNoSelection(material.genetically_no_selection)
        setGeneticallyModified(material.genetically_modified)
        setGeneticallyNoDifference(material.genetically_no_difference)
        if (material.constitute_material.length > 0) {
          material.constitute_material.forEach((materialInfo) => {
            constituteMaterialNameInfos.push({
              constituteMaterialName: materialInfo.material_name,
              isGenetically: {
                noSelection: materialInfo.genetically_no_selection,
                modified: materialInfo.genetically_modified,
                noDifference: materialInfo.genetically_no_difference,
              },
            })
          })
        } else {
          constituteMaterialNameInfos.push({
            constituteMaterialName: '',
            isGenetically: {
              noSelection: true,
              modified: false,
              noDifference: false,
            },
          })
        }
        setConstituteMaterialNameInfos(constituteMaterialNameInfos)
        if (material.base_allergens.length === 0) {
          setBaseHaveAllergen(false)
        }
        material.base_allergens.forEach((allergen) => {
          handleBaseAllelgen(allergen)
        })
        if (material.additive.length > 0) {
          // await Promise.all(
          //   material.additive.map(async (additiveInfo) => {
          //     const additiveUseId = additiveInfo.additive_use_ref.id
          //       ? additiveInfo.additive_use_ref.id
          //       : // @ts-ignore
          //       additiveInfo.additive_use_ref._key.path.segments[6]
          //     additiveUseInfos.push({
          //       use: additiveUseId,
          //       substance: additiveInfo.additive_material
          //     })
          //   })
          // )
          material.additive.forEach((additiveInfo) => {
            const additiveUseId = additiveInfo.additive_use_ref
              ? additiveInfo.additive_use_ref.id
                ? additiveInfo.additive_use_ref.id
                : // @ts-ignore
                  additiveInfo.additive_use_ref._key.path.segments[6]
              : ''
            additiveUseInfos.push({
              use: additiveUseId,
              substance: additiveInfo.additive_material
                ? additiveInfo.additive_material
                : '',
              disabled_material_name: additiveInfo.disabled_material_name,
            })
          })
        } else {
          additiveUseInfos.push({
            use: '',
            substance: '',
            disabled_material_name: false,
          })
        }
        setAdditiveUseInfos(additiveUseInfos)
        if (
          material.internal_capacity &&
          material.internal_capacity.length > 0
        ) {
          material.internal_capacity.forEach((internalCapacityInfo) => {
            internalCapacityInfos.push({
              internalCapacityCount:
                internalCapacityInfo.internal_capacity_count
                  ? String(internalCapacityInfo.internal_capacity_count)
                  : '',
              internalCapacityUnit: internalCapacityInfo.internal_capacity_unit
                ? internalCapacityInfo.internal_capacity_unit
                : 'g',
              numberItem: internalCapacityInfo.number_item
                ? String(internalCapacityInfo.number_item)
                : '',
              numberItemUnit: internalCapacityInfo.number_item_unit
                ? internalCapacityInfo.number_item_unit
                : '',
              jancode: internalCapacityInfo.jancode
                ? internalCapacityInfo.jancode
                : '',
              originName: internalCapacityInfo.origin_name
                ? internalCapacityInfo.origin_name
                : '',
            })
          })
        } else {
          internalCapacityInfos.push({
            internalCapacityCount: '',
            internalCapacityUnit: 'g',
            numberItem: '',
            numberItemUnit: '',
            jancode: '',
            originName: '',
          })
        }
        setInternalCapacityInfos(internalCapacityInfos)
        if (material.additive_allergens.length === 0) {
          setAdditiveHaveAllergen(false)
        }
        material.additive_allergens.forEach((allergen) => {
          handleAdditiveAllelgen(allergen)
        })
        setEnergy(String(material.energy))
        setProtein(String(material.protein))
        setLipid(String(material.lipid))
        setCarbohydrate(String(material.carbohydrate))
        setSaltEquivalent(String(material.salt_equivalent))
        setSourceInformation(material.source_information)
        setUrl1(material.urls[0])
        setUrl2(material.urls[1])
        setUrl3(material.urls[2])
        setClassifiedName(material.classified_name)
        setAuthor(material.author)
        setMemo(material.memo)
        setCreatedAt(
          material.created_at
            ? dayjs(material.created_at.toDate())
                .format('YYYY-MM-DD HH:mm:ss')
                .toString()
            : '',
        )
        setUpdatedAt(
          material.updated_at
            ? dayjs(material.updated_at.toDate())
                .format('YYYY-MM-DD HH:mm:ss')
                .toString()
            : '',
        )
      } else {
        constituteMaterialNameInfos.push({
          constituteMaterialName: '',
          isGenetically: {
            noSelection: true,
            modified: false,
            noDifference: false,
          },
        })
        setConstituteMaterialNameInfos(constituteMaterialNameInfos)
        additiveUseInfos.push({
          use: '',
          substance: '',
          disabled_material_name: false,
        })
        setAdditiveUseInfos(additiveUseInfos)
        internalCapacityInfos.push({
          internalCapacityCount: '',
          internalCapacityUnit: 'g',
          numberItem: '',
          numberItemUnit: '',
          jancode: '',
          originName: '',
        })
        setInternalCapacityInfos(internalCapacityInfos)
      }
      dispatch(setIsLoading({ isLoading: false }))
    },
    [material, materialId],
  )

  useEffect(() => {
    setMaterialInfo(materialId)
  }, [])

  return (
    <div className={styles.container}>
      <DataLoad />
      <PageHedding title="原材料登録" to="/material" />
      <div className={styles.material_container}>
        <div className={styles.material_section}>基本情報</div>
        <SelectBoxWithLabel
          required={true}
          labelName="大カテゴリ"
          description="該当する大カテゴリーを選択してください。"
          line={1}
          size="large"
          value={category}
          options={categoryList}
          errorText={categoryErrorText}
          onChange={(event) => handleCategory(event.target.value)}
        />
        <SelectBoxWithLabel
          required={true}
          labelName="小カテゴリ"
          description="該当する小カテゴリーを選択してください。"
          line={1}
          size="large"
          value={subCategory}
          options={subCategoryFilterList}
          errorText={subCategoryErrorText}
          onChange={(event) => handleSubCategory(event.target.value)}
        />
        <InputTextSuggestWithLabel
          required={true}
          labelName="メーカー"
          description="この製品のメーカーを入力してください。"
          line={1}
          type="text"
          size="large"
          value={manufacturer}
          placeholder="sacri"
          errorText={manufacturerErrorText}
          suggestType="material_manufacturer"
          onSelect={handleManufacturerSuggest}
          onChange={(event) => handleManufacturer(event.target.value)}
        />
        <InputTextWithLabel
          required={true}
          labelName="製品名"
          description="製品名を規格書通りに入力してください。<br>製品名の頭に★マークをつけてください。<br>※ダブルチェック終了時に★マークを外します。"
          line={1}
          type="text"
          size="large"
          value={productName}
          placeholder="カメリヤ"
          errorText={productNameErrorText}
          onChange={(event) => handleProductName(event.target.value)}
        />
        <InputTextWithLabel
          required={true}
          labelName="ふりがな"
          description="ひらがなで記入してください。<br>例)<br>「三井J上白糖1」→みついじぇいじょうはくとういち"
          line={1}
          type="text"
          size="large"
          value={productNameKana}
          placeholder="かめりや"
          errorText={productNameKanaErrorText}
          onChange={(event) => handleProductNameKana(event.target.value)}
        />
        <TextAreaWithLabel
          required={false}
          labelName="表記ゆれ候補"
          description="原材料を検索する際に引っかかるように、表記ゆれの候補を記載してください。<br>※カンマ(,)区切りで入力してください。<br>※カタカナ、ひらがな、半角、全角は候補の入力の必要はありません。<br>※英語や漢字が混ざるものについては候補を入力する必要があります。<br>例1)　間違いやすいもの<br>製品名：カメリア<br>表記ゆれ候補：カメリヤ<br>例2）　製品名がひらがなだが、漢字の入力が考えられるもの<br>製品名：おおやまウインナー<br>表記ゆれ候補：大山ウインナー"
          labelPosition="top"
          line={3}
          maxLength={1000}
          rows={4}
          size="large"
          value={notationFluctuation}
          placeholder="カメリア"
          onChange={(event) => handleNotationFluctuation(event.target.value)}
        />
        <InputTextWithLabel
          required={false}
          labelName="原料原産地名"
          description="【製品が加工品の場合】（複合原材料＝加工品です。）<br>どこの国で製造されているかを確認し、〇〇製造と入力してください。国内で製造されている場合は、国内製造と入力してください。<br><br>【製品が生鮮品の場合】<br>国名を入力してください。〇〇産と記載されている場合でも、国名のみ入力してください。北海道産など日本で生産されている場合は国産と入力してください。<br>※小麦粉は全て国内製造と入力してください。<br>※原産国が3カ国以上ある場合は3カ国目以降を『その他』と表示してください。<br><br>例）<br>アメリカ産、フランス産等→アメリカ、フランス、その他<br>小麦粉→国内製造<br>加工品で製造が日本→国内製造"
          line={1}
          type="text"
          size="large"
          value={originName}
          placeholder="国内製造"
          onChange={(event) => handleOriginName(event.target.value)}
        />
        <RadioButtonWithLabel
          required={false}
          labelName="原材料種類"
          description="原材料が1つの場合は単一原材料、2種類以上の原材料からなる場合は複合原材料を選択してください。"
          line={1}
          radioSize="large"
          checkInfos={[
            {
              isChecked: singleMaterial,
              label: '単一原材料',
              onChange: () => handleSingleOrCompositeMaterial('single'),
            },
            {
              isChecked: compositeMaterial,
              label: '複合原材料',
              onChange: () => handleSingleOrCompositeMaterial('composite'),
            },
            {
              isChecked: noMaterial,
              label: 'なし',
              onChange: () => handleSingleOrCompositeMaterial('no'),
            },
          ]}
        />
        {singleMaterial ? (
          <>
            <InputTextWithLabel
              required={true}
              labelName="名称"
              description="製品の代表的な名称を入力してください。<br>例）<br>製品名：あらびきポークウインナーソーセージ<br>名称：ウインナーソーセージ"
              line={1}
              type="text"
              size="large"
              value={materialName}
              placeholder="あいうえお"
              errorText={materialNameErrorText}
              onChange={(event) => handleMaterialName(event.target.value)}
            />
            <RadioButtonAndInputTextWithLabel
              required={true}
              labelName="構成原材料名"
              description="規格書の原材料名をそのまま入力してください。<br>  ※（乳成分を含む）などのアレルゲン情報は省いて記載してください。アレルゲンは別に選択する箇所があります。<br>  ※遺伝子組み換えの記載がある原材料、遺伝子組み換え不分別の記載がある原材料の場合にいずれかを選択をしてください。それ以外の場合は〝選択なし〟のままにしてください。"
              line={1}
              radioSize="middle2"
              checkInfos={[
                {
                  isChecked: geneticallyNoSelection,
                  label: '選択なし',
                  onChange: () =>
                    handleGenetically({
                      noSelection: true,
                      modified: false,
                      noDifference: false,
                    }),
                },
                {
                  isChecked: geneticallyNoDifference,
                  label: '不分別',
                  onChange: () =>
                    handleGenetically({
                      noSelection: false,
                      modified: false,
                      noDifference: true,
                    }),
                },
                {
                  isChecked: geneticallyModified,
                  label: '遺伝子組換え',
                  onChange: () =>
                    handleGenetically({
                      noSelection: false,
                      modified: true,
                      noDifference: false,
                    }),
                },
              ]}
              type="text"
              size="large"
              value={rawMaterialName}
              placeholder="あいうえお"
              errorText={rawMaterialNameErrorText}
              onChange={(event) => handleRawMaterialName(event.target.value)}
            />
          </>
        ) : (
          ''
        )}
        {compositeMaterial ? (
          <InputTextWithLabel
            required={true}
            labelName="名称"
            description="製品の代表的な名称を入力してください。<br>例）<br>製品名：あらびきポークウインナーソーセージ<br>名称：ウインナーソーセージ"
            line={1}
            type="text"
            size="large"
            value={compositeMaterialName}
            placeholder="あいうえお"
            errorText={compositeMaterialNameErrorText}
            onChange={(event) =>
              handleCompositeMaterialName(event.target.value)
            }
          />
        ) : (
          ''
        )}
        {compositeMaterial ? (
          <AddFormConstituteMaterialNameInformation
            formInfos={constituteMaterialNameInfos}
            handleConstituteMaterialNameInfos={
              handleConstituteMaterialNameInfos
            }
            errorText={constituteMaterialNameErrorText}
          />
        ) : (
          ''
        )}
        {singleMaterial || compositeMaterial ? (
          <>
            <RadioButtonWithLabel
              required={true}
              labelName="アレルゲン(特定原材料)"
              description="特定原材料が含まれる場合対象のアレルゲンにチェックをしてください。"
              line={1}
              checkInfos={[
                {
                  isChecked: baseHaveAllergen,
                  label: 'あり',
                  onChange: handleBaseHaveAllergen,
                },
                {
                  isChecked: !baseHaveAllergen,
                  label: 'なし',
                  onChange: handleBaseHaveAllergen,
                },
              ]}
              errorText={baseAllergenErrorText}
            />
            <CheckBoxWithLabel
              required={false}
              labelName=""
              labelTextWrap={true}
              description=""
              labelPosition="top"
              line={1}
              checkInfos={[
                {
                  isChecked: baseHaveAllergen ? baseAllergenEgg : false,
                  label: '卵',
                  size: 'semi_small',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.EGG),
                  readOnly: !baseHaveAllergen,
                },
                {
                  isChecked: baseHaveAllergen ? baseAllergenMilk : false,
                  label: '乳成分',
                  size: 'small',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.MILK),
                  readOnly: !baseHaveAllergen,
                },
                {
                  isChecked: baseHaveAllergen ? baseAllergenWheat : false,
                  label: '小麦',
                  size: 'semi_small',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.WHEAT),
                  readOnly: !baseHaveAllergen,
                },
                {
                  isChecked: baseHaveAllergen ? baseAllergenShrimp : false,
                  label: 'えび',
                  size: 'semi_small',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.SHRIMP),
                  readOnly: !baseHaveAllergen,
                },
                {
                  isChecked: baseHaveAllergen ? baseAllergenCrub : false,
                  label: 'かに',
                  size: 'semi_small',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.CRUB),
                  readOnly: !baseHaveAllergen,
                },
                {
                  isChecked: baseHaveAllergen ? baseAllergenPeanuts : false,
                  label: '落花生',
                  size: 'small',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.PEANUTS),
                  readOnly: !baseHaveAllergen,
                },
                {
                  isChecked: baseHaveAllergen ? baseAllergenSoba : false,
                  label: 'そば',
                  size: 'semi_small',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.SOBA),
                  readOnly: !baseHaveAllergen,
                },
                {
                  isChecked: baseHaveAllergen ? baseAllergenAlmond : false,
                  label: 'アーモンド',
                  size: 'semi_middle',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.ALMOND),
                  readOnly: !baseHaveAllergen,
                },
                {
                  isChecked: baseHaveAllergen ? baseAllergenAbalone : false,
                  label: 'あわび',
                  size: 'semi_small',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.ABALONE),
                  readOnly: !baseHaveAllergen,
                },
                {
                  isChecked: baseHaveAllergen ? baseAllergenSquid : false,
                  label: 'いか',
                  size: 'semi_small',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.SQUID),
                  readOnly: !baseHaveAllergen,
                },
                {
                  isChecked: baseHaveAllergen ? baseAllergenIkura : false,
                  label: 'いくら',
                  size: 'semi_small',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.IKURA),
                  readOnly: !baseHaveAllergen,
                },
                {
                  isChecked: baseHaveAllergen ? baseAllergenOrange : false,
                  label: 'オレンジ',
                  size: 'semi_middle',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.ORANGE),
                  readOnly: !baseHaveAllergen,
                },
                {
                  isChecked: baseHaveAllergen ? baseAllergenCashewnuts : false,
                  label: 'カシューナッツ',
                  size: 'middle',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.CASHEWNUTS),
                  readOnly: !baseHaveAllergen,
                },
                {
                  isChecked: baseHaveAllergen ? baseAllergenKiwifruit : false,
                  label: 'キウイフルーツ',
                  size: 'middle',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.KIWIFRUIT),
                  readOnly: !baseHaveAllergen,
                },
                {
                  isChecked: baseHaveAllergen ? baseAllergenBeef : false,
                  label: '牛肉',
                  size: 'semi_small',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.BEEF),
                  readOnly: !baseHaveAllergen,
                },
                {
                  isChecked: baseHaveAllergen ? baseAllergenWalnut : false,
                  label: 'くるみ',
                  size: 'semi_small',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.WALNUT),
                  readOnly: !baseHaveAllergen,
                },
                {
                  isChecked: baseHaveAllergen ? baseAllergenSesame : false,
                  label: 'ごま',
                  size: 'semi_small',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.SESAME),
                  readOnly: !baseHaveAllergen,
                },
                {
                  isChecked: baseHaveAllergen ? baseAllergenSalmon : false,
                  label: 'さけ',
                  size: 'semi_small',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.SALMON),
                  readOnly: !baseHaveAllergen,
                },
                {
                  isChecked: baseHaveAllergen ? baseAllergenMackerel : false,
                  label: 'さば',
                  size: 'semi_small',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.MACKEREL),
                  readOnly: !baseHaveAllergen,
                },
                {
                  isChecked: baseHaveAllergen ? baseAllergenSoy : false,
                  label: '大豆',
                  size: 'semi_small',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.SOY),
                  readOnly: !baseHaveAllergen,
                },
                {
                  isChecked: baseHaveAllergen ? baseAllergenChicken : false,
                  label: '鶏肉',
                  size: 'semi_small',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.CHICKEN),
                  readOnly: !baseHaveAllergen,
                },
                {
                  isChecked: baseHaveAllergen ? baseAllergenBanana : false,
                  label: 'バナナ',
                  size: 'semi_small',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.BANANA),
                  readOnly: !baseHaveAllergen,
                },
                {
                  isChecked: baseHaveAllergen ? baseAllergenPork : false,
                  label: '豚肉',
                  size: 'semi_small',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.PORK),
                  readOnly: !baseHaveAllergen,
                },
                {
                  isChecked: baseHaveAllergen
                    ? baseAllergenMacadamianuts
                    : false,
                  label: 'マカダミアナッツ',
                  size: 'semi_middle',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.MACADAMIANUTS),
                  readOnly: !baseHaveAllergen,
                },
                {
                  isChecked: baseHaveAllergen ? baseAllergenPeach : false,
                  label: 'もも',
                  size: 'semi_small',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.PEACH),
                  readOnly: !baseHaveAllergen,
                },
                {
                  isChecked: baseHaveAllergen ? baseAllergenYam : false,
                  label: 'やまいも',
                  size: 'semi_middle',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.YAM),
                  readOnly: !baseHaveAllergen,
                },
                {
                  isChecked: baseHaveAllergen ? baseAllergenApple : false,
                  label: 'りんご',
                  size: 'semi_small',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.APPLE),
                  readOnly: !baseHaveAllergen,
                },
                {
                  isChecked: baseHaveAllergen ? baseAllergenGelatine : false,
                  label: 'ゼラチン',
                  size: 'semi_middle',
                  type: 'default',
                  onChange: () => handleBaseAllelgen(Allergens.GELATINE),
                  readOnly: !baseHaveAllergen,
                },
              ]}
            />
          </>
        ) : (
          ''
        )}
        <div className={styles.material_section}>添加物</div>
        <AddFormAdditiveUseInformation
          // TODO: 初期リロード時にstateが空配列で渡ってしまう不具合を調査し修正
          formInfos={additiveUseInfos}
          handleAdditiveUseInfos={handleAdditiveUseInfos}
        />
        <RadioButtonWithLabel
          required={true}
          labelName="アレルゲン(特定原材料)"
          description="特定原材料が含まれる場合対象のアレルゲンにチェックをしてください。"
          line={1}
          checkInfos={[
            {
              isChecked: additiveHaveAllergen,
              label: 'あり',
              onChange: handleAdditiveHaveAllergen,
            },
            {
              isChecked: !additiveHaveAllergen,
              label: 'なし',
              onChange: handleAdditiveHaveAllergen,
            },
          ]}
          errorText={additiveAllergenErrorText}
        />
        <CheckBoxWithLabel
          required={false}
          labelName=""
          labelTextWrap={true}
          description=""
          labelPosition="top"
          line={1}
          checkInfos={[
            {
              isChecked: additiveHaveAllergen ? additiveAllergenEgg : false,
              label: '卵',
              size: 'semi_small',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.EGG),
              readOnly: !additiveHaveAllergen,
            },
            {
              isChecked: additiveHaveAllergen ? additiveAllergenMilk : false,
              label: '乳成分',
              size: 'small',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.MILK),
              readOnly: !additiveHaveAllergen,
            },
            {
              isChecked: additiveHaveAllergen ? additiveAllergenWheat : false,
              label: '小麦',
              size: 'semi_small',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.WHEAT),
              readOnly: !additiveHaveAllergen,
            },
            {
              isChecked: additiveHaveAllergen ? additiveAllergenShrimp : false,
              label: 'えび',
              size: 'semi_small',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.SHRIMP),
              readOnly: !additiveHaveAllergen,
            },
            {
              isChecked: additiveHaveAllergen ? additiveAllergenCrub : false,
              label: 'かに',
              size: 'semi_small',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.CRUB),
              readOnly: !additiveHaveAllergen,
            },
            {
              isChecked: additiveHaveAllergen ? additiveAllergenPeanuts : false,
              label: '落花生',
              size: 'small',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.PEANUTS),
              readOnly: !additiveHaveAllergen,
            },
            {
              isChecked: additiveHaveAllergen ? additiveAllergenSoba : false,
              label: 'そば',
              size: 'semi_small',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.SOBA),
              readOnly: !additiveHaveAllergen,
            },
            {
              isChecked: additiveHaveAllergen ? additiveAllergenAlmond : false,
              label: 'アーモンド',
              size: 'semi_middle',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.ALMOND),
              readOnly: !additiveHaveAllergen,
            },
            {
              isChecked: additiveHaveAllergen ? additiveAllergenAbalone : false,
              label: 'あわび',
              size: 'semi_small',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.ABALONE),
              readOnly: !additiveHaveAllergen,
            },
            {
              isChecked: additiveHaveAllergen ? additiveAllergenSquid : false,
              label: 'いか',
              size: 'semi_small',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.SQUID),
              readOnly: !additiveHaveAllergen,
            },
            {
              isChecked: additiveHaveAllergen ? additiveAllergenIkura : false,
              label: 'いくら',
              size: 'semi_small',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.IKURA),
              readOnly: !additiveHaveAllergen,
            },
            {
              isChecked: additiveHaveAllergen ? additiveAllergenOrange : false,
              label: 'オレンジ',
              size: 'semi_middle',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.ORANGE),
              readOnly: !additiveHaveAllergen,
            },
            {
              isChecked: additiveHaveAllergen
                ? additiveAllergenCashewnuts
                : false,
              label: 'カシューナッツ',
              size: 'middle',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.CASHEWNUTS),
              readOnly: !additiveHaveAllergen,
            },
            {
              isChecked: additiveHaveAllergen
                ? additiveAllergenKiwifruit
                : false,
              label: 'キウイフルーツ',
              size: 'middle',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.KIWIFRUIT),
              readOnly: !additiveHaveAllergen,
            },
            {
              isChecked: additiveHaveAllergen ? additiveAllergenBeef : false,
              label: '牛肉',
              size: 'semi_small',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.BEEF),
              readOnly: !additiveHaveAllergen,
            },
            {
              isChecked: additiveHaveAllergen ? additiveAllergenWalnut : false,
              label: 'くるみ',
              size: 'semi_small',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.WALNUT),
              readOnly: !additiveHaveAllergen,
            },
            {
              isChecked: additiveHaveAllergen ? additiveAllergenSesame : false,
              label: 'ごま',
              size: 'semi_small',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.SESAME),
              readOnly: !additiveHaveAllergen,
            },
            {
              isChecked: additiveHaveAllergen ? additiveAllergenSalmon : false,
              label: 'さけ',
              size: 'semi_small',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.SALMON),
              readOnly: !additiveHaveAllergen,
            },
            {
              isChecked: additiveHaveAllergen
                ? additiveAllergenMackerel
                : false,
              label: 'さば',
              size: 'semi_small',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.MACKEREL),
              readOnly: !additiveHaveAllergen,
            },
            {
              isChecked: additiveHaveAllergen ? additiveAllergenSoy : false,
              label: '大豆',
              size: 'semi_small',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.SOY),
              readOnly: !additiveHaveAllergen,
            },
            {
              isChecked: additiveHaveAllergen ? additiveAllergenChicken : false,
              label: '鶏肉',
              size: 'semi_small',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.CHICKEN),
              readOnly: !additiveHaveAllergen,
            },
            {
              isChecked: additiveHaveAllergen ? additiveAllergenBanana : false,
              label: 'バナナ',
              size: 'semi_small',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.BANANA),
              readOnly: !additiveHaveAllergen,
            },
            {
              isChecked: additiveHaveAllergen ? additiveAllergenPork : false,
              label: '豚肉',
              size: 'semi_small',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.PORK),
              readOnly: !additiveHaveAllergen,
            },
            {
              isChecked: additiveHaveAllergen
                ? additiveAllergenMacadamianuts
                : false,
              label: 'マカダミアナッツ',
              size: 'semi_middle',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.MACADAMIANUTS),
              readOnly: !additiveHaveAllergen,
            },
            {
              isChecked: additiveHaveAllergen ? additiveAllergenPeach : false,
              label: 'もも',
              size: 'semi_small',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.PEACH),
              readOnly: !additiveHaveAllergen,
            },
            {
              isChecked: additiveHaveAllergen ? additiveAllergenYam : false,
              label: 'やまいも',
              size: 'semi_middle',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.YAM),
              readOnly: !additiveHaveAllergen,
            },
            {
              isChecked: additiveHaveAllergen ? additiveAllergenApple : false,
              label: 'りんご',
              size: 'semi_small',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.APPLE),
              readOnly: !additiveHaveAllergen,
            },
            {
              isChecked: additiveHaveAllergen
                ? additiveAllergenGelatine
                : false,
              label: 'ゼラチン',
              size: 'semi_middle',
              type: 'default',
              onChange: () => handleAdditiveAllelgen(Allergens.GELATINE),
              readOnly: !additiveHaveAllergen,
            },
          ]}
        />
        <div className={styles.material_section}>
          栄養成分表示（100gあたり）※推定値
        </div>
        <InputTextAndUnitWithLabel
          required={true}
          labelName="エネルギー"
          description="100g当たりの数値を入力してください。規格書によっては、 100g当たりの記載でない場合があるためご注意ください。その時は計算が必要です。<br>※数値は半角で入力してください。"
          line={2}
          type="text"
          size="middle"
          value={energy}
          placeholder=""
          unit="kcal"
          errorText={energyErrorText}
          onChange={(event) => handleEnergy(event.target.value)}
        />
        <InputTextAndUnitWithLabel
          required={true}
          labelName="タンパク質"
          description="100g当たりの数値を入力してください。規格書によっては、 100g当たりの記載でない場合があるためご注意ください。その時は計算が必要です。<br>※数値は半角で入力してください。"
          line={2}
          type="text"
          size="middle"
          value={protein}
          placeholder=""
          unit="g"
          errorText={proteinErrorText}
          onChange={(event) => handleProtein(event.target.value)}
        />
        <InputTextAndUnitWithLabel
          required={true}
          labelName="脂質"
          description="100g当たりの数値を入力してください。規格書によっては、 100g当たりの記載でない場合があるためご注意ください。その時は計算が必要です。<br>※数値は半角で入力してください。"
          line={2}
          type="text"
          size="middle"
          value={lipid}
          placeholder=""
          unit="g"
          errorText={lipidErrorText}
          onChange={(event) => handleLipid(event.target.value)}
        />
        <InputTextAndUnitWithLabel
          required={true}
          labelName="炭水化物"
          description="規格書に炭水化物の記載がなく、糖質、食物繊維が記載されている場合は、「炭水化物＝糖質＋食物繊維」として炭水化物を計算し、入力してください。<br>※数値は半角で入力してください。"
          line={2}
          type="text"
          size="middle"
          value={carbohydrate}
          placeholder=""
          unit="g"
          errorText={carbohydrateErrorText}
          onChange={(event) => handleCarbohydrate(event.target.value)}
        />
        <InputTextAndUnitWithLabel
          required={true}
          labelName="食塩相当量"
          description="食塩相当量は、ナトリウムで記載されていることがあります。その場合には、下のナトリウム計算を使って食塩相当量を反映してください。<br>※数値は半角で入力してください。"
          line={2}
          type="text"
          size="middle"
          value={saltEquivalent}
          placeholder=""
          unit="g"
          errorText={saltEquivalentErrorText}
          onChange={(event) => handleSaltEquivalent(event.target.value)}
        />
        <div className={styles.material_flex}>
          <InputTextAndUnitWithLabel
            required={false}
            labelName="ナトリウム計算"
            description="食塩相当量がナトリウムで記載されている場合は、ここにナトリウムの数値を入力してください。右の〝反映する〟ボタンをタップすると自動で食塩相当量に入力されます。<br>※数値は半角で入力してください。"
            line={2}
            type="text"
            size="semimiddle2"
            value={sodiumCalculation}
            placeholder=""
            unit="mg"
            onChange={(event) => handleSodiumCalculation(event.target.value)}
          />
          <SubmitButton
            label="反映する"
            color="light_gray"
            size="rectangle_small"
            icon="none"
            onClick={() => handleSodiumCalculationSubmit()}
          />
        </div>
        <div className={styles.material_section}>その他</div>
        <SelectBoxWithLabel
          required={false}
          labelName="情報元"
          description="情報元を選択してください。"
          line={1}
          size="large"
          value={sourceInformation}
          options={[
            {
              is_default: true,
              label: '----',
              value: '',
            },
            {
              is_default: false,
              label: '規格書',
              value: 'standard',
            },
            {
              is_default: false,
              label: 'WEB',
              value: 'web',
            },
            {
              is_default: false,
              label: '日本食品標準成分表2020年版 八訂',
              value: 'eight_orders',
            },
            {
              is_default: false,
              label: 'パッケージ',
              value: 'package',
            },
          ]}
          onChange={(event) => handleSourceInformation(event.target.value)}
        />
        {sourceInformation === 'web' ? (
          <div>
            <InputTextWithLabel
              required={false}
              labelName="URL"
              description="情報元がwebの場合は、こちらにURLリンクを貼り付けてください。<br>複数のリンクを入力できます。"
              line={1}
              type="text"
              size="large"
              value={url1}
              placeholder=""
              onChange={(event) => handleUrl1(event.target.value)}
            />
            <InputTextWithLabel
              required={false}
              labelName=""
              description=""
              line={1}
              type="text"
              size="large"
              margin="narrow"
              value={url2}
              placeholder=""
              onChange={(event) => handleUrl2(event.target.value)}
            />
            <InputTextWithLabel
              required={false}
              labelName=""
              description=""
              line={1}
              type="text"
              size="large"
              margin="narrow"
              value={url3}
              placeholder=""
              onChange={(event) => handleUrl3(event.target.value)}
            />
          </div>
        ) : (
          ''
        )}
        {sourceInformation === 'eight_orders' ? (
          <div>
            <InputTextWithLabel
              required={false}
              labelName="分類名"
              description="情報元が日本食品標準成分表2020年版 八訂の場合は、分類名を入力してください。"
              line={1}
              type="text"
              size="large"
              value={classifiedName}
              placeholder=""
              onChange={(event) => handleClassifiedName(event.target.value)}
            />
          </div>
        ) : (
          ''
        )}
        <InputTextWithLabel
          required={false}
          labelName="登録者"
          description="登録者を入力してください。"
          line={1}
          type="text"
          size="large"
          value={author}
          placeholder=""
          onChange={(event) => handleAuthor(event.target.value)}
        />

        <TextAreaWithLabel
          required={false}
          labelName="メモ"
          description="・規格書の店舗名と、ページ数を入力してください。<br>例）メゾンクロス　P32<br><br>・ダブルチェック後は実施記録を入力してください。<br>例）2022/10/30 小西<br><br>・その他疑問点などあればここに記載してください。"
          labelPosition="top"
          line={1}
          maxLength={1000}
          rows={8}
          size="large"
          value={memo}
          placeholder="ここにメモを記入できます。"
          onChange={(event) => handleMemo(event.target.value)}
        />

        {materialId ? (
          <>
            <InputTextWithLabel
              required={false}
              labelName="入力日"
              description=""
              line={1}
              type="text"
              size="large"
              value={createdAt}
              placeholder=""
              readOnly={true}
            />
            <InputTextWithLabel
              required={false}
              labelName="更新日"
              description=""
              line={1}
              type="text"
              size="large"
              value={updatedAt}
              placeholder=""
              readOnly={true}
            />
          </>
        ) : (
          ''
        )}

        <div className={styles.material_submit}>
          {/* <SubmitButton
            label="保存し中断する"
            color="orange_outlined"
            size="large"
            icon="none"
            onClick={() => handleSubmit('/initload')}
          /> */}
          {/* <span className={styles.breadAddButtonSpacer}></span> */}
          <SubmitButton
            label="原材料を保存する"
            color="orange"
            size="large"
            icon="none"
            onClick={() => handleSubmit('/material')}
          />
        </div>
      </div>
    </div>
  )
}

export default MaterialOperationTemplate
