import { FC, useState, useCallback, ChangeEvent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import styles from 'styles/components/template.module.scss'
import PageHedding from 'components/PageHedding/PageHedding'
import InputTextWithLabel from 'components/Form/WithLabel/InputText'
import TextAreaWithLabel from 'components/Form/WithLabel/TextArea'
import SelectBoxWithLabel from 'components/Form/WithLabel/SelectBox'
import { SelectOption } from 'components/Form/SelectBox'
import InputTextAndUnitWithLabel from 'components/Form/WithLabel/InputTextAndUnit'
import InputTextAndUnitDoubleWithLabel from 'components/Form/WithLabel/InputTextAndUnitDouble'
import CheckBoxWithLabel from 'components/Form/WithLabel/CheckBox'
import RadioButtonWithLabel from 'components/Form/WithLabel/RadioButton'
import RadioButtonAndInputTextAndUnitWithLabel from 'components/Form/WithLabel/RadioButtonAndInputTextAndUnit'
import FileUploadButtonWithLabel from 'components/Button/WithLabel/FileUploadButton'
import SubmitButton from 'components/Button/SubmitButton'
import ImagePreview from 'components/ImagePreview/ImagePreview'
import BreadAddTabHeader from 'components/Tab/BreadAddTabHeader'
import {
  createBread,
  uploadBreadImages,
  updateImageUrl,
  Allergens,
  updateBread,
  getBreadById,
  clearBread,
  BreadType,
  BreadRepresentationLawName,
  BreadRepresentationLawNameLabel,
} from 'reducks/bread/slice'
import { getRecipeByBreadId, RecipeType } from 'reducks/recipe/slice'
import { DataType } from 'reducks/business/slice'
import { getCategories, CategoryType } from 'reducks/category/slice'
import {
  getShopSubscriptionById,
  ShopSubscriptionType,
} from 'reducks/shopSubscriptions/slice'
import { Staff } from 'reducks/staff/slice'
import { TAX, EAT_IN_TAX } from 'Constants'
import { RootState } from 'reducks/reducers'
import { setIsLoading } from 'reducks/loading/slice'
import { httpsCallable } from 'firebase/functions'
import { functions } from 'fb/index'

const BreadAddTemplate: FC = () => {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const location = useLocation()
  // 更新の場合か確認
  const path = location.pathname
  const breadId = path.split('/bread/edit/')[1]
    ? path.split('/bread/edit/')[1]
    : null
  const copyBreadId = path.split('/bread/copy/')[1]
    ? path.split('/bread/copy/')[1]
    : null
  const [toRecipe, setToRecipe] = useState<string>('')
  const [breadName, setBreadName] = useState<string>('')
  const [breadShortName, setBreadShortName] = useState<string>('')
  const [breadAuthor, setBreadAuthor] = useState<string>('')
  const [breadPriceCardName, setBreadPriceCardName] = useState<string>('')
  const [breadImages, setBreadImages] = useState<File[] | null>(null)
  const [breadImagesChanged, setBreadImagesChanged] = useState<boolean>(false)
  const [breadPriceCardDescription, setBreadPriceCardDescription] =
    useState<string>('')
  const [breadCategory, setBreadCategory] = useState<string>('')
  const [breadCategoryList, setBreadCategoryList] = useState<SelectOption[]>([])
  const [breadPriceCardPrice, setBreadPriceCardPrice] = useState<string>('')
  const [breadEatInPrice, setBreadEatInPrice] = useState<string>('')
  // const [breadAllergen, setBreadAllergen] = useState<string[]>([])
  const [breadAllergenEgg, setBreadAllergenEgg] = useState<boolean>(false)
  const [breadAllergenMilk, setBreadAllergenMilk] = useState<boolean>(false)
  const [breadAllergenWheat, setBreadAllergenWheat] = useState<boolean>(false)
  const [breadAllergenShrimp, setBreadAllergenShrimp] = useState<boolean>(false)
  const [breadAllergenCrub, setBreadAllergenCrub] = useState<boolean>(false)
  const [breadAllergenPeanuts, setBreadAllergenPeanuts] =
    useState<boolean>(false)
  const [breadAllergenSoba, setBreadAllergenSoba] = useState<boolean>(false)
  const [breadAllergenWalnut, setBreadAllergenWalnut] = useState<boolean>(false)
  const [breadHaveAllergen, setBreadHaveAllergen] = useState<boolean>(true)
  const [breadRepresentationLawName, setBreadRepresentationLawName] =
    useState<string>('')
  const [breadRepresentationLawNameOther, setBreadRepresentationLawNameOther] =
    useState<string>('')
  const [breadConsumptionPeriod, setBreadConsumptionPeriod] =
    useState<boolean>(true)
  const [breadAppreciationPeriod, setBreadAppreciationPeriod] =
    useState<boolean>(false)
  const [breadPeriodDate, setBreadPeriodDate] = useState<string>('')
  const [
    breadConsumptionPeriodAfterThawing,
    setBreadConsumptionPeriodAfterThawing,
  ] = useState<boolean>(true)
  const [
    breadAppreciationPeriodAfterThawing,
    setBreadAppreciationPeriodAfterThawing,
  ] = useState<boolean>(false)
  const [breadPeriodDateAfterThawing, setBreadPeriodDateAfterThawing] =
    useState<string>('')
  const [breadPreservationMethod, setBreadPreservationMethod] =
    useState<string>('')
  const [breadSaling, setBreadSaling] = useState<boolean>(true)
  const [breadSalesSuspension, setBreadSalesSuspension] =
    useState<boolean>(false)
  const [breadPackagingCost, setBreadPackagingCost] = useState<string>('')
  const [breadMemo, setBreadMemo] = useState<string>('')
  const [breadContamination, setBreadContamination] = useState<string>('')
  const [isStandardPlan, setIsStandardPlan] = useState<boolean>(false)

  // const { categories } = useSelector((state: RootState) => state.categorySlice)
  const createCategoryList = useCallback(async () => {
    const categoriesPayload = await dispatch(getCategories())
    const categories: CategoryType[] = categoriesPayload.payload
    // const categoriesPayload: CategoryType[] = categories.payload
    const categoryList: SelectOption[] = [
      {
        is_default: true,
        label: '----',
        value: '',
      },
    ]
    categories.forEach((category) => {
      // TODO: リロード時(初回読み込み時)にFirestore参照型からidが取得できないための対応。根本原因を調査する。
      const id = category.category_ref.id
        ? category.category_ref.id
        : // @ts-ignore
          category.category_ref._key.path.segments[6]
      categoryList.push({
        is_default: false,
        label: category.name,
        value: id,
      })
    })
    setBreadCategoryList(categoryList)
  }, [dispatch])

  useEffect(() => {
    createCategoryList()
  }, [])

  const handleBreadName = (breadName: string) => {
    setBreadName(breadName)
  }
  const handleBreadShortName = (breadShortName: string) => {
    setBreadShortName(breadShortName)
  }
  const handleBreadAuthor = (breadAuthor: string) => {
    setBreadAuthor(breadAuthor)
  }
  const handleBreadPriceCardName = (breadPriceCardName: string) => {
    setBreadPriceCardName(breadPriceCardName)
  }
  // const handleBreadImage = (breadImage:File|null) => {
  //   setBreadImage(breadImage)
  // }
  const handleBreadPriceCardDescription = (
    breadPriceCardDescription: string,
  ) => {
    setBreadPriceCardDescription(breadPriceCardDescription)
  }
  const handleBreadCategory = (breadCategory: string) => {
    setBreadCategory(breadCategory)
  }
  const handleBreadPriceCardPrice = (breadPriceCardPrice: string) => {
    // 半角数字に変換
    breadPriceCardPrice = breadPriceCardPrice.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(breadPriceCardPrice) && Number(breadPriceCardPrice) !== 0) {
      return
    }
    setBreadPriceCardPrice(breadPriceCardPrice)
  }
  const handleBreadEatInPrice = (breadEatInPrice: string) => {
    // 半角数字に変換
    breadEatInPrice = breadEatInPrice.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(breadEatInPrice) && Number(breadEatInPrice) !== 0) {
      return
    }
    setBreadEatInPrice(breadEatInPrice)
  }
  // const handleBreadAllelgenEgg = () => {
  //   console.log(breadAllergenEgg)
  //   setBreadAllergenEgg(!breadAllergenEgg ? true : false)
  // }
  const handleBreadAllelgen = (setAllelgen: string) => {
    // const newBreadAllergen = [...breadAllergen]
    // console.log(newBreadAllergen)
    // if (newBreadAllergen.includes(setAllelgen)) {
    //   console.log("pop")
    //   newBreadAllergen.filter((value) => value !== setAllelgen)
    // } else {
    //   console.log("push")
    //   newBreadAllergen.push(setAllelgen)
    //   console.log(newBreadAllergen)
    // }
    // console.log(newBreadAllergen)
    // setBreadAllergen((prevState) => [...prevState, ...newBreadAllergen])
    if (setAllelgen === Allergens.EGG) {
      setBreadAllergenEgg(!breadAllergenEgg ? true : false)
    }
    if (setAllelgen === Allergens.MILK) {
      setBreadAllergenMilk(!breadAllergenMilk ? true : false)
    }
    if (setAllelgen === Allergens.WHEAT) {
      setBreadAllergenWheat(!breadAllergenWheat ? true : false)
    }
    if (setAllelgen === Allergens.SHRIMP) {
      setBreadAllergenShrimp(!breadAllergenShrimp ? true : false)
    }
    if (setAllelgen === Allergens.CRUB) {
      setBreadAllergenCrub(!breadAllergenCrub ? true : false)
    }
    if (setAllelgen === Allergens.PEANUTS) {
      setBreadAllergenPeanuts(!breadAllergenPeanuts ? true : false)
    }
    if (setAllelgen === Allergens.SOBA) {
      setBreadAllergenSoba(!breadAllergenSoba ? true : false)
    }
    if (setAllelgen === Allergens.WALNUT) {
      setBreadAllergenWalnut(!breadAllergenWalnut ? true : false)
    }
  }
  const handleBreadHaveAllergen = () => {
    setBreadHaveAllergen(!breadHaveAllergen ? true : false)
    setBreadAllergenEgg(false)
    setBreadAllergenMilk(false)
    setBreadAllergenWheat(false)
    setBreadAllergenShrimp(false)
    setBreadAllergenCrub(false)
    setBreadAllergenPeanuts(false)
    setBreadAllergenSoba(false)
    setBreadAllergenWalnut(false)
  }
  const handleBreadRepresentationLawName = (
    breadRepresentationLawName: string,
  ) => {
    setBreadRepresentationLawName(breadRepresentationLawName)
  }
  const handleBreadRepresentationLawNameOther = (
    breadRepresentationLawNameOther: string,
  ) => {
    setBreadRepresentationLawNameOther(breadRepresentationLawNameOther)
  }
  const handleBreadPeriodRepresentation = () => {
    setBreadConsumptionPeriod(!breadConsumptionPeriod ? true : false)
    setBreadAppreciationPeriod(!breadAppreciationPeriod ? true : false)
  }
  const handleBreadPeriodDate = (breadPeriodDate: string) => {
    // 半角数字に変換
    breadPeriodDate = breadPeriodDate.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(breadPeriodDate) && Number(breadPeriodDate) !== 0) {
      return
    }
    setBreadPeriodDate(breadPeriodDate)
  }
  const handleBreadPeriodRepresentationAfterThawing = () => {
    setBreadConsumptionPeriodAfterThawing(
      !breadConsumptionPeriodAfterThawing ? true : false,
    )
    setBreadAppreciationPeriodAfterThawing(
      !breadAppreciationPeriodAfterThawing ? true : false,
    )
  }
  const handleBreadPeriodDateAfterThawing = (
    breadPeriodDateAfterThawing: string,
  ) => {
    // 半角数字に変換
    breadPeriodDateAfterThawing = breadPeriodDateAfterThawing.replace(
      /[０-９]/g,
      (s) => {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
      },
    )
    if (
      !Number(breadPeriodDateAfterThawing) &&
      Number(breadPeriodDateAfterThawing) !== 0
    ) {
      return
    }
    setBreadPeriodDateAfterThawing(breadPeriodDateAfterThawing)
  }
  const handleBreadPreservationMethod = (breadPreservationMethod: string) => {
    setBreadPreservationMethod(breadPreservationMethod)
  }
  const handleBreadSalesStatus = () => {
    setBreadSaling(!breadSaling ? true : false)
    setBreadSalesSuspension(!breadSalesSuspension ? true : false)
  }
  const handleBreadPackagingCost = (breadPackagingCost: string) => {
    // 半角数字に変換
    breadPackagingCost = breadPackagingCost.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(breadPackagingCost) && Number(breadPackagingCost) !== 0) {
      return
    }
    setBreadPackagingCost(breadPackagingCost)
  }
  const handleBreadMemo = (breadMemo: string) => {
    setBreadMemo(breadMemo)
  }
  const handleBreadContamination = (breadContamination: string) => {
    setBreadContamination(breadContamination)
  }
  // const handleBreadAlelgenEgg = useCallback(
  //   () => {
  //     setBreadAlelgenEgg(!breadAlelgenEgg ? true : false)
  //   },
  //   [breadAlelgenEgg],
  // )

  const changeFileHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target?.files && event.target.files.length > 0) {
        setBreadImagesChanged(true)
        let newBredImages: File[] = []
        if (breadImages) {
          newBredImages = [...breadImages]
        }
        const files = Array.from(event.target.files)
        const addFiles: File[] = []
        let isError = false
        files.forEach((file) => {
          if (
            [
              'image/jpeg',
              'image/png',
              'image/heif',
              'image/heif-sequence',
            ].indexOf(file.type) < 0
          ) {
            isError = true
            setBreadImageErrorText('対応形式でない画像はアップロードできません')
          } else if (file.size > 10485760) {
            isError = true
            setBreadImageErrorText(
              '容量の大きすぎる画像はアップロードできません',
            )
          } else {
            addFiles.push(file)
          }
        })
        if (!isError) {
          setBreadImageErrorText(null)
        }
        setBreadImages([...addFiles, ...newBredImages])
        event.target.value = ''
      }
    },
    [breadImages],
  )

  const resetHandler = useCallback(
    (index: number) => {
      if (breadImages) {
        const newBreadImages = breadImages.filter((_, i) => i !== index)
        setBreadImageErrorText(null)
        setBreadImages(newBreadImages.length > 0 ? newBreadImages : null)
      }
    },
    [breadImages],
  )

  // Validation
  const [breadNameErrorText, setBreadNameErrorText] = useState<string | null>(
    null,
  )
  const [breadCategoryErrorText, setBreadCategoryErrorText] = useState<
    string | null
  >(null)
  const [
    breadRepresentationLawNameErrorText,
    setBreadRepresentationLawNameErrorText,
  ] = useState<string | null>(null)
  const [
    breadRepresentationLawNameOtherErrorText,
    setBreadRepresentationLawNameOtherErrorText,
  ] = useState<string | null>(null)
  const [breadPeriodDateErrorText, setBreadPeriodDateErrorText] = useState<
    string | null
  >(null)
  const [
    breadPreservationMethodErrorText,
    setBreadPreservationMethodErrorText,
  ] = useState<string | null>(null)
  const [breadImageErrorText, setBreadImageErrorText] = useState<string | null>(
    null,
  )
  const [breadAllergenErrorText, setBreadAllergenErrorText] = useState<
    string | null
  >(null)

  const { staff } = useSelector((state: RootState) => state.staffSlice)

  const initPlanInfo = useCallback(
    async (staff: Staff | null) => {
      // スタンダードプランか否か情報を取得する
      if (staff && staff.storeId) {
        const shopSubscriptionPayload = await dispatch(
          getShopSubscriptionById({
            storeId: staff.storeId,
          }),
        )
        const shopSubscription: ShopSubscriptionType =
          shopSubscriptionPayload.payload
        if (shopSubscription && shopSubscription.is_daseruno_standard_plan) {
          setIsStandardPlan(true)
        }
        // 全体管理者の人もスタンダードプラン機能が使える
        if (staff.isAdmin) {
          setIsStandardPlan(true)
        }
      }
    },
    [staff],
  )

  useEffect(() => {
    initPlanInfo(staff)
  }, [])

  const initBreadInfo = useCallback(
    async (breadId: string | null, copyBreadId: string | null) => {
      dispatch(setIsLoading({ isLoading: true }))
      if (!staff) {
        navigate('/initload?path=' + path)
        window.scrollTo(0, 0)
        dispatch(setIsLoading({ isLoading: false }))
        return
      }
      await dispatch(clearBread())
      if (!breadId && !copyBreadId) {
        setToRecipe('')
        dispatch(setIsLoading({ isLoading: false }))
        return
      } else if (breadId) {
        setToRecipe('/bread/edit/recipe/' + breadId)
      } else if (copyBreadId) {
        setBreadImagesChanged(true)
        setToRecipe('')
      } else {
        // 異常系ではある
        setToRecipe('')
        dispatch(setIsLoading({ isLoading: false }))
        return
      }
      const getBreadId: string = breadId
        ? breadId
        : copyBreadId
        ? copyBreadId
        : ''
      if (!getBreadId) {
        dispatch(setIsLoading({ isLoading: false }))
        return
      }
      const breadPayload = await dispatch(getBreadById({ id: getBreadId }))
      const bread: BreadType = breadPayload.payload
      if (!bread) {
        // 商品が取得できない場合はTOPに飛ばす
        navigate('/initload')
        window.scrollTo(0, 0)
        dispatch(setIsLoading({ isLoading: false }))
        return
      }
      const breadName: string = copyBreadId
        ? bread.name + '（コピー）'
        : bread.name
      setBreadName(breadName)
      setBreadShortName(bread.short_name)
      setBreadAuthor(bread.author ? bread.author : '')
      setBreadPriceCardName(bread.price_card_name ? bread.price_card_name : '')
      setBreadPriceCardDescription(
        bread.price_card_description
          ? bread.price_card_description
          : bread.description
          ? bread.description.replace('<p>', '').replace('</p>', '')
          : '',
      )
      const categoryId =
        bread.category && bread.category.id
          ? bread.category.id
          : // @ts-ignore
            bread.category._key.path.segments[6]
      setBreadCategory(categoryId)
      setBreadPriceCardPrice(
        bread.selling_price ? String(bread.selling_price) : '0',
      )
      setBreadEatInPrice(bread.eat_in_price ? String(bread.eat_in_price) : '')
      if (bread.allergens.length === 0) {
        setBreadHaveAllergen(false)
      }
      if (bread.allergens.includes(Allergens.EGG)) {
        setBreadAllergenEgg(true)
      }
      if (bread.allergens.includes(Allergens.MILK)) {
        setBreadAllergenMilk(true)
      }
      if (bread.allergens.includes(Allergens.WHEAT)) {
        setBreadAllergenWheat(true)
      }
      if (bread.allergens.includes(Allergens.SHRIMP)) {
        setBreadAllergenShrimp(true)
      }
      if (bread.allergens.includes(Allergens.CRUB)) {
        setBreadAllergenCrub(true)
      }
      if (bread.allergens.includes(Allergens.PEANUTS)) {
        setBreadAllergenPeanuts(true)
      }
      if (bread.allergens.includes(Allergens.SOBA)) {
        setBreadAllergenSoba(true)
      }
      if (bread.allergens.includes(Allergens.WALNUT)) {
        setBreadAllergenWalnut(true)
      }
      setBreadRepresentationLawName(
        bread.representation_law_name ? bread.representation_law_name : '',
      )
      setBreadRepresentationLawNameOther(
        bread.representation_law_name_other
          ? bread.representation_law_name_other
          : '',
      )
      setBreadConsumptionPeriod(bread.is_consumption_period ? true : false)
      setBreadAppreciationPeriod(bread.is_consumption_period ? false : true)
      setBreadPeriodDate(
        bread.period_date || bread.period_date === 0
          ? String(bread.period_date)
          : '',
      )
      setBreadConsumptionPeriodAfterThawing(
        bread.is_consumption_period_after_thawing ? true : false,
      )
      setBreadAppreciationPeriodAfterThawing(
        bread.is_consumption_period_after_thawing ? false : true,
      )
      setBreadPeriodDateAfterThawing(
        bread.period_date_after_thawing || bread.period_date_after_thawing === 0
          ? String(bread.period_date_after_thawing)
          : '',
      )
      setBreadPreservationMethod(
        bread.preservation_method ? bread.preservation_method : '',
      )
      setBreadSaling(bread.is_salling ? true : false)
      setBreadSalesSuspension(bread.is_salling ? false : true)
      setBreadPackagingCost(
        bread.packaging_cost ? String(bread.packaging_cost) : '',
      )
      setBreadMemo(bread.memo ? bread.memo : '')
      setBreadContamination(bread.contamination ? bread.contamination : '')
      if (bread.image_urls) {
        const breadImages: File[] = []
        await Promise.all(
          bread.image_urls.map(async (url: string) => {
            const response = await fetch(url)
            const blob = await response.blob()
            const file = new File([blob], 'image.jpg')
            breadImages.push(file)
          }),
        )
        setBreadImages(breadImages)
      }
      dispatch(setIsLoading({ isLoading: false }))
    },
    [breadId],
  )

  useEffect(() => {
    initBreadInfo(breadId, copyBreadId)
  }, [])

  const validation = () => {
    let isError = false
    if (!breadName) {
      isError = true
      setBreadNameErrorText('入力されておりません')
    }
    if (!breadImages || breadImages.length === 0) {
      isError = true
      setBreadImageErrorText('画像がアップロードされていません')
    }
    if (!breadCategory) {
      isError = true
      setBreadCategoryErrorText('入力されておりません')
    }
    if (!breadRepresentationLawName) {
      isError = true
      setBreadRepresentationLawNameErrorText('入力されておりません')
    }
    if (
      breadRepresentationLawName === BreadRepresentationLawName.OTHER &&
      !breadRepresentationLawNameOther
    ) {
      isError = true
      setBreadRepresentationLawNameOtherErrorText('入力されておりません')
    }
    if (!breadPeriodDate) {
      isError = true
      setBreadPeriodDateErrorText('入力されておりません')
    }
    if (!breadPreservationMethod) {
      isError = true
      setBreadPreservationMethodErrorText('入力されておりません')
    }
    if (
      breadHaveAllergen &&
      !breadAllergenEgg &&
      !breadAllergenMilk &&
      !breadAllergenWheat &&
      !breadAllergenShrimp &&
      !breadAllergenCrub &&
      !breadAllergenPeanuts &&
      !breadAllergenSoba &&
      !breadAllergenWalnut
    ) {
      isError = true
      setBreadAllergenErrorText('入力されておりません')
    }
    if (isError) {
      window.scrollTo(0, 0)
      return false
    }
    return true
  }

  const handleSubmit = async (to: string = '', is_draft: boolean = false) => {
    if (!is_draft) {
      const is_valid = validation()
      if (!is_valid) {
        return
      }
    }
    dispatch(setIsLoading({ isLoading: true }))
    const allergens: string[] = []
    if (breadAllergenEgg) {
      allergens.push(Allergens.EGG)
    }
    if (breadAllergenMilk) {
      allergens.push(Allergens.MILK)
    }
    if (breadAllergenWheat) {
      allergens.push(Allergens.WHEAT)
    }
    if (breadAllergenShrimp) {
      allergens.push(Allergens.SHRIMP)
    }
    if (breadAllergenCrub) {
      allergens.push(Allergens.CRUB)
    }
    if (breadAllergenPeanuts) {
      allergens.push(Allergens.PEANUTS)
    }
    if (breadAllergenSoba) {
      allergens.push(Allergens.SOBA)
    }
    if (breadAllergenWalnut) {
      allergens.push(Allergens.WALNUT)
    }

    let createDocId: string | null = null
    if (breadId) {
      const recipePayload = await dispatch(getRecipeByBreadId({ id: breadId }))
      const recipe: RecipeType = recipePayload.payload
      // Firestoreを更新
      const createResult = await dispatch(
        updateBread({
          id: breadId,
          name: breadName,
          short_name: breadShortName,
          price_card_name: breadPriceCardName ? breadPriceCardName : null,
          author: breadAuthor,
          price_card_description: breadPriceCardDescription
            ? breadPriceCardDescription
            : null,
          categoey_doc_id: breadCategory,
          selling_price: Number(breadPriceCardPrice),
          eat_in_price: Number(breadEatInPrice),
          allergens: allergens,
          representation_law_name: breadRepresentationLawName,
          representation_law_name_other: breadRepresentationLawNameOther,
          is_consumption_period: breadConsumptionPeriod,
          period_date: Number(breadPeriodDate),
          is_consumption_period_after_thawing:
            breadConsumptionPeriodAfterThawing,
          period_date_after_thawing: breadPeriodDateAfterThawing
            ? Number(breadPeriodDateAfterThawing)
            : null,
          preservation_method: breadPreservationMethod,
          is_salling: breadSaling,
          packaging_cost: breadPackagingCost
            ? Number(breadPackagingCost)
            : null,
          memo: breadMemo,
          contamination: breadContamination,
          is_draft: is_draft,
        }),
      )
      createDocId = createResult.payload

      // 裏貼りシールステータスと原価を更新する
      const updateBackSealStatusAndCostDaseruno = httpsCallable(
        functions,
        'updateBackSealStatusAndCostDaseruno',
      )
      if (recipe) {
        await updateBackSealStatusAndCostDaseruno({
          docId: recipe.id,
          dataType: DataType.RECIPE,
          breadId: breadId,
          shopId: staff?.shopId,
        })
      }
    } else {
      // Firestoreに登録
      const createResult = await dispatch(
        createBread({
          name: breadName,
          short_name: breadShortName,
          price_card_name: breadPriceCardName ? breadPriceCardName : null,
          author: breadAuthor,
          price_card_description: breadPriceCardDescription
            ? breadPriceCardDescription
            : null,
          categoey_doc_id: breadCategory,
          selling_price: Number(breadPriceCardPrice),
          eat_in_price: Number(breadEatInPrice),
          allergens: allergens,
          representation_law_name: breadRepresentationLawName,
          representation_law_name_other: breadRepresentationLawNameOther,
          is_consumption_period: breadConsumptionPeriod,
          period_date: Number(breadPeriodDate),
          is_consumption_period_after_thawing:
            breadConsumptionPeriodAfterThawing,
          period_date_after_thawing: breadPeriodDateAfterThawing
            ? Number(breadPeriodDateAfterThawing)
            : null,
          preservation_method: breadPreservationMethod,
          is_salling: breadSaling,
          packaging_cost: breadPackagingCost
            ? Number(breadPackagingCost)
            : null,
          memo: breadMemo,
          contamination: breadContamination,
          is_draft: is_draft,
        }),
      )
      createDocId = createResult.payload
    }
    if (!createDocId) {
      dispatch(setIsLoading({ isLoading: false }))
      return
    }
    // Storageにアップロード
    if (createDocId && breadImagesChanged) {
      if (breadImages !== null) {
        const uploadResult = await dispatch(
          uploadBreadImages({
            breadId: createDocId,
            images: breadImages,
          }),
        )
        // Firestoreの画像パスを更新
        await dispatch(
          updateImageUrl({
            breadId: createDocId,
            image_urls: uploadResult.payload,
          }),
        )
      }
    }
    if (to === 'recipe') {
      if (copyBreadId) {
        navigate(
          '/bread/edit/recipe/' + createDocId + '?copyBreadId=' + copyBreadId,
        )
      } else {
        navigate('/bread/edit/recipe/' + createDocId)
      }
    }
    if (to === 'detail') {
      navigate('/bread/detail/' + createDocId)
    }
    if (to === 'top') {
      navigate('/initload')
    }
    window.scrollTo(0, 0)
    dispatch(setIsLoading({ isLoading: false }))
  }

  useEffect(() => {
    // イベントの設定
    window.history.pushState(null, document.title)
    window.addEventListener('beforeunload', onUnload)
    window.addEventListener('popstate', onBrouserBack)

    return () => {
      // イベントの設定解除
      window.removeEventListener('beforeunload', onUnload)
      window.removeEventListener('popstate', onBrouserBack)
    }
  }, [])

  const onUnload = (e: any) => {
    e.preventDefault()
    e.returnValue = ''
  }

  const onBrouserBack = () => {
    if (window.confirm('このサイトから離れてもよろしいですか？')) {
      navigate(-2)
    }
  }

  // const checkControllHandler = useCallback(
  //   (index: number) => {
  //     if (breadImages) {
  //       const newBreadImages = breadImages.filter((_, i) => i !== index)
  //       setBreadImages(newBreadImages.length > 0 ? newBreadImages : null)
  //     }
  //   },
  //   [breadImages],
  // )

  return (
    <div className={styles.container}>
      <PageHedding title="商品登録" to="/initload" />
      <BreadAddTabHeader
        isRecipe={false}
        toBread=""
        toRecipe={toRecipe}
        handleSubmit={() => handleSubmit('recipe')}
      />
      <div className={styles.breadAddformContainer}>
        <InputTextWithLabel
          required={true}
          labelName="商品名"
          description="登録する商品名を入力してください。"
          line={1}
          type="text"
          size="large"
          value={breadName}
          placeholder="サクッとサクリパン"
          errorText={breadNameErrorText}
          onChange={(event) => handleBreadName(event.target.value)}
        />
        <InputTextWithLabel
          required={false}
          labelName="商品の短縮名"
          description="このフィールドが入力されている場合、管理画面上ではこちらの表記が優先されて表示されます。"
          line={1}
          type="text"
          size="large"
          value={breadShortName}
          placeholder="sacri"
          onChange={(event) => handleBreadShortName(event.target.value)}
        />
        <InputTextWithLabel
          required={false}
          labelName="入力担当者名"
          description="商品基本情報を入力している担当者のお名前を入力してください。"
          line={1}
          type="text"
          size="large"
          value={breadAuthor}
          placeholder="山本太郎"
          onChange={(event) => handleBreadAuthor(event.target.value)}
        />
        <InputTextWithLabel
          required={false}
          labelName="プライスカード表示名"
          description="プライスカードに表示させる商品名を入力してください。"
          line={2}
          type="text"
          size="large"
          value={breadPriceCardName ? breadPriceCardName : ''}
          placeholder="サクッとサクリパン"
          onChange={(event) => handleBreadPriceCardName(event.target.value)}
        />
        <FileUploadButtonWithLabel
          required={true}
          labelName="イメージ画像"
          description="商品画像を登録すると、ホーム画面に画像が表示されます。"
          line={1}
          buttonLabelName="画像をアップロード"
          buttonDescription="( JPEG / PNG / HEIC 10MB以下 )"
          onChange={changeFileHandler}
        />
        <div className={styles.previewImageErrorText}>
          {breadImageErrorText}
        </div>
        {breadImages && (
          <div className={styles.previewImageContainer}>
            {breadImages.map((breadImage, index) => (
              <ImagePreview
                file={breadImage}
                onClick={() => resetHandler(index)}
              />
            ))}
          </div>
        )}
        <TextAreaWithLabel
          required={false}
          labelName="商品説明文　　　(100文字以内)"
          description="商品説明を入力してください。"
          labelPosition="center"
          line={3}
          maxLength={100}
          rows={2}
          size="large"
          value={breadPriceCardDescription}
          placeholder="サックサク生地に甘いカスタードクリームたっぷりのデニッシュパン"
          onChange={(event) =>
            handleBreadPriceCardDescription(event.target.value)
          }
        />
        <SelectBoxWithLabel
          required={true}
          labelName="カテゴリー"
          description="カテゴリーを選択してください。"
          line={1}
          size="large"
          value={breadCategory}
          options={breadCategoryList}
          errorText={breadCategoryErrorText}
          onChange={(event) => handleBreadCategory(event.target.value)}
        />
        <InputTextAndUnitDoubleWithLabel
          required={false}
          labelName="店頭販売価格(税抜)"
          description="消費税の計算に関して<br>ダセルーノでは、消費税を小数点第一位で切り捨てした金額を税込金額とさせていただいておます。<br>予めご理解いただきますようよろしくお願いいたします。"
          line={2}
          type="text"
          size="small"
          value={breadPriceCardPrice}
          placeholder="0,000"
          unit="円"
          unitDescription="(税抜)"
          value2={
            breadPriceCardPrice
              ? String(Math.floor(Number(breadPriceCardPrice) * TAX))
              : ''
          }
          placeholder2="0,000"
          unit2="円"
          unitDescription2="(税込)"
          readOnly2={true}
          onChange={(event) => handleBreadPriceCardPrice(event.target.value)}
        />
        <InputTextAndUnitDoubleWithLabel
          required={false}
          labelName="イートイン　(税抜)"
          description="消費税の計算に関して<br>ダセルーノでは、消費税を小数点第一位で切り捨てした金額を税込金額とさせていただいておます。<br>予めご理解いただきますようよろしくお願いいたします。"
          line={2}
          type="text"
          size="small"
          value={breadEatInPrice}
          placeholder=""
          unit="円"
          unitDescription="(税抜)"
          value2={
            breadEatInPrice
              ? String(Math.floor(Number(breadEatInPrice) * EAT_IN_TAX))
              : ''
          }
          placeholder2=""
          unit2="円"
          unitDescription2="(税込)"
          readOnly2={true}
          onChange={(event) => handleBreadEatInPrice(event.target.value)}
        />
        <RadioButtonWithLabel
          required={true}
          labelName="アレルゲン(特定原材料)"
          description="使用している特定原材料にチェックを入れてください。"
          line={1}
          checkInfos={[
            {
              isChecked: breadHaveAllergen,
              label: 'あり',
              onChange: handleBreadHaveAllergen,
            },
            {
              isChecked: !breadHaveAllergen,
              label: 'なし',
              onChange: handleBreadHaveAllergen,
            },
          ]}
          errorText={breadAllergenErrorText}
        />
        <CheckBoxWithLabel
          required={false}
          labelName=""
          labelTextWrap={true}
          description=""
          labelPosition="top"
          line={1}
          checkInfos={[
            {
              isChecked: breadHaveAllergen ? breadAllergenEgg : false,
              label: '卵',
              size: 'semi_small',
              type: 'default',
              onChange: () => handleBreadAllelgen(Allergens.EGG),
              readOnly: !breadHaveAllergen,
            },
            {
              isChecked: breadHaveAllergen ? breadAllergenMilk : false,
              label: '乳成分',
              size: 'small',
              type: 'default',
              onChange: () => handleBreadAllelgen(Allergens.MILK),
              readOnly: !breadHaveAllergen,
            },
            {
              isChecked: breadHaveAllergen ? breadAllergenWheat : false,
              label: '小麦',
              size: 'semi_small',
              type: 'default',
              onChange: () => handleBreadAllelgen(Allergens.WHEAT),
              readOnly: !breadHaveAllergen,
            },
            {
              isChecked: breadHaveAllergen ? breadAllergenShrimp : false,
              label: 'えび',
              size: 'semi_small',
              type: 'default',
              onChange: () => handleBreadAllelgen(Allergens.SHRIMP),
              readOnly: !breadHaveAllergen,
            },
            {
              isChecked: breadHaveAllergen ? breadAllergenCrub : false,
              label: 'かに',
              size: 'semi_small',
              type: 'default',
              onChange: () => handleBreadAllelgen(Allergens.CRUB),
              readOnly: !breadHaveAllergen,
            },
            {
              isChecked: breadHaveAllergen ? breadAllergenPeanuts : false,
              label: '落花生',
              size: 'small',
              type: 'default',
              onChange: () => handleBreadAllelgen(Allergens.PEANUTS),
              readOnly: !breadHaveAllergen,
            },
            {
              isChecked: breadHaveAllergen ? breadAllergenSoba : false,
              label: 'そば',
              size: 'semi_small',
              type: 'default',
              onChange: () => handleBreadAllelgen(Allergens.SOBA),
              readOnly: !breadHaveAllergen,
            },
            {
              isChecked: breadHaveAllergen ? breadAllergenWalnut : false,
              label: 'くるみ',
              size: 'small',
              type: 'default',
              onChange: () => handleBreadAllelgen(Allergens.WALNUT),
              readOnly: !breadHaveAllergen,
            },
          ]}
        />
        <SelectBoxWithLabel
          required={true}
          labelName="食品表示法上の名称"
          description="裏貼りシール（食品表示ラベル）に記載する名称を選択してください。"
          line={2}
          size="large"
          value={breadRepresentationLawName}
          options={[
            {
              is_default: true,
              label: '----',
              value: '',
            },
            {
              is_default: false,
              label: BreadRepresentationLawNameLabel.RECTANGULAR_BREAD,
              value: BreadRepresentationLawName.RECTANGULAR_BREAD,
            },
            {
              is_default: false,
              label: BreadRepresentationLawNameLabel.SWEETENED_BUN,
              value: BreadRepresentationLawName.SWEETENED_BUN,
            },
            {
              is_default: false,
              label: BreadRepresentationLawNameLabel.BREAD,
              value: BreadRepresentationLawName.BREAD,
            },
            {
              is_default: false,
              label: BreadRepresentationLawNameLabel.BAKED_SWEETS,
              value: BreadRepresentationLawName.BAKED_SWEETS,
            },
            {
              is_default: false,
              label: BreadRepresentationLawNameLabel.WESTERN_SWEETS,
              value: BreadRepresentationLawName.WESTERN_SWEETS,
            },
            {
              is_default: false,
              label: BreadRepresentationLawNameLabel.FRESH_WESTERN_SWEETS,
              value: BreadRepresentationLawName.FRESH_WESTERN_SWEETS,
            },
            {
              is_default: false,
              label: BreadRepresentationLawNameLabel.OTHER,
              value: BreadRepresentationLawName.OTHER,
            },
          ]}
          errorText={breadRepresentationLawNameErrorText}
          onChange={(event) =>
            handleBreadRepresentationLawName(event.target.value)
          }
        />
        {breadRepresentationLawName === BreadRepresentationLawName.OTHER ? (
          <InputTextWithLabel
            required={true}
            labelName="食品表示法上の名称(その他)"
            description="裏貼りシール（食品表示ラベル）に記載する名称を入力してください。"
            line={1}
            type="text"
            size="large"
            value={breadRepresentationLawNameOther}
            placeholder="食パン"
            errorText={breadRepresentationLawNameOtherErrorText}
            onChange={(event) =>
              handleBreadRepresentationLawNameOther(event.target.value)
            }
          />
        ) : (
          ''
        )}
        <RadioButtonAndInputTextAndUnitWithLabel
          required={true}
          labelName="期限表示"
          description="消費期限か賞味期限を選択後、日数を入力してください。"
          line={1}
          checkInfos={[
            {
              isChecked: breadConsumptionPeriod,
              label: '消費期限',
              onChange: handleBreadPeriodRepresentation,
            },
            {
              isChecked: breadAppreciationPeriod,
              label: '賞味期限',
              onChange: handleBreadPeriodRepresentation,
            },
          ]}
          type="text"
          size="xsmall"
          value={breadPeriodDate}
          placeholder="製造から00"
          unit="日"
          errorText={breadPeriodDateErrorText}
          onChange={(event) => handleBreadPeriodDate(event.target.value)}
        />
        <RadioButtonAndInputTextAndUnitWithLabel
          required={false}
          labelName="冷凍時期限表示"
          description="消費期限か賞味期限を選択後、日数を入力してください。"
          line={1}
          checkInfos={[
            {
              isChecked: breadConsumptionPeriodAfterThawing,
              label: '消費期限',
              onChange: handleBreadPeriodRepresentationAfterThawing,
            },
            {
              isChecked: breadAppreciationPeriodAfterThawing,
              label: '賞味期限',
              onChange: handleBreadPeriodRepresentationAfterThawing,
            },
          ]}
          type="text"
          size="xsmall"
          value={breadPeriodDateAfterThawing}
          placeholder="製造から00"
          unit="日"
          onChange={(event) =>
            handleBreadPeriodDateAfterThawing(event.target.value)
          }
        />
        <SelectBoxWithLabel
          required={true}
          labelName="保存方法"
          description="保存方法を選択してください。"
          line={1}
          size="large"
          value={breadPreservationMethod}
          options={[
            {
              is_default: true,
              label: '----',
              value: '',
            },
            {
              is_default: false,
              label: '直射日光・高温多湿を避けお早目にお召し上がり下さい。',
              value: 'avoid_sunlight',
            },
            {
              is_default: false,
              label: '要冷蔵（10℃以下で保存して下さい。）',
              value: 'refrigerated',
            },
            {
              is_default: false,
              label: '要冷凍（-18℃以下で保存して下さい。）',
              value: 'frozen',
            },
          ]}
          errorText={breadPreservationMethodErrorText}
          onChange={(event) =>
            handleBreadPreservationMethod(event.target.value)
          }
        />
        <RadioButtonWithLabel
          required={true}
          labelName="店頭販売状況"
          description="店頭販売状況を選択してください。"
          line={1}
          checkInfos={[
            {
              isChecked: breadSaling,
              label: '販売中',
              onChange: handleBreadSalesStatus,
            },
            {
              isChecked: breadSalesSuspension,
              label: '販売停止',
              onChange: handleBreadSalesStatus,
            },
          ]}
        />
        {isStandardPlan ? (
          <InputTextAndUnitWithLabel
            required={false}
            labelName="包材費"
            description=""
            line={1}
            type="text"
            size="small"
            value={breadPackagingCost}
            unit="円"
            unitDescription="(税込)"
            placeholder="0,000"
            onChange={(event) => handleBreadPackagingCost(event.target.value)}
          />
        ) : (
          ''
        )}
        <TextAreaWithLabel
          required={false}
          labelName="メモ"
          description=""
          labelPosition="top"
          line={1}
          maxLength={1000}
          rows={8}
          size="large"
          value={breadMemo}
          placeholder="ここにメモを記入できます。"
          onChange={(event) => handleBreadMemo(event.target.value)}
        />
        <TextAreaWithLabel
          required={false}
          labelName="コンタミネーション"
          description=""
          labelPosition="top"
          line={1}
          maxLength={1000}
          rows={8}
          size="large"
          value={breadContamination}
          placeholder=""
          onChange={(event) => handleBreadContamination(event.target.value)}
        />
      </div>
      <div className={styles.breadAddButtonContainer}>
        <SubmitButton
          label="キャンセル"
          color="orange_outlined"
          size="large"
          icon="none"
          onClick={() => navigate('/initload')}
        />
        <span className={styles.breadAddButtonSpacer}></span>
        <SubmitButton
          label="保存し中断する"
          color="orange_outlined"
          size="large"
          icon="none"
          onClick={() => handleSubmit('detail', true)}
        />
        <span className={styles.breadAddButtonSpacer}></span>
        <SubmitButton
          label="登録(更新)する"
          color="orange"
          size="large"
          icon="none"
          onClick={() => handleSubmit('detail')}
        />
        <span className={styles.breadAddButtonSpacer}></span>
        <SubmitButton
          label="レシピ入力を行う"
          color="orange"
          size="large"
          icon="none"
          onClick={() => handleSubmit('recipe')}
        />
      </div>
    </div>
  )
}

export default BreadAddTemplate
